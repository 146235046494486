<template>
	<button class="v-button">
		<slot/>
	</button>
</template>

<script>
export default {
  mounted() {
    this.$el.ons(this.$listeners);
  }
};
</script>

<style lang="less">
.v-button {
  outline: none;
  cursor: pointer;
  font-size: 14px;
  padding: 0px 15px;
  user-select: none;
  line-height: 28px;
  // border-radius: 4px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  transition: 200ms;
  &:not(:disabled) {
    &:hover {
      filter: brightness(0.95);
    }

    &:active {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25) inset;
    }

    &[line] {
      &:hover {
        filter: brightness(0.95);
      }

      &:active {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) inset;
      }
    }
  }
  &[type="primary"] {
    color: #ffffff;
    border-color: #41ac76;
    background-color: #41ac76;

    &[line] {
      color: #41ac76;
      background-color: #ffffff;
    }
  }
  &[type="info"] {
    color: #ffffff;
    border-color: #1890ff;
    background-color: #1890ff;

    &[line] {
      color: #1890ff;
      background-color: #ffffff;
    }
  }
  &[type="warning"] {
    color: #ffffff;
    border-color: #faad14;
    background-color: #faad14;

    &[line] {
      color: #faad14;
      background-color: #ffffff;
    }
  }
  &[type="danger"] {
    color: #ffffff;
    border-color: #f5222d;
    background-color: #f5222d;

    &[line] {
      color: #f5222d;
      background-color: #ffffff;
    }
  }
  &[size="small"] {
    font-size: 12px;
    line-height: 22px;
    border-radius: 2px;
  }
  &[size="large"] {
    font-size: 16px;
    line-height: 34px;
    border-radius: 6px;
  }
  &[theme="pill"] {
    font-size: 12px;
    padding: 0px 10px;
    line-height: 22px;
    border-radius: 12px;
  }
}
</style>