<template>
	<div class="v-pagination" v-if="total>0">
    <div class="label">
      <slot name="label" v-if="'label' in $slots"/>
      <template v-else>{{String.template(label,{index,size,total,count})}}</template>
    </div>
		<div class="btns">
      <div class="btn prev" @click="()=>go(index-1)">
        <slot name="prev" v-if="'prev' in $slots"/>
        <i class="ss-passport icon-arrow-left" v-else/>
      </div>
      <div class="btn before" :title="buttons[0]-1" v-if="buttons[0]!=1" @click="()=>go(buttons[0]-1)">...</div>
      <div class="btn go" :class="{active:index==i}" v-for="i in buttons" :key="i" @click="go(i)">
        <slot v-if="'default' in $scopedSlots" :index="i"/>
        <template v-else>{{i}}</template>
      </div>
      <div class="btn after" :title="buttons[buttons.length-1]+1" v-if="buttons[buttons.length-1]!=count" @click="()=>go(buttons[buttons.length-1]+1)">...</div>
      <div class="btn next" @click="()=>go(index+1)">
        <slot name="next" v-if="'next' in $slots"/>
        <i class="ss-passport icon-arrow-right" v-else/>
      </div>
    </div>
	</div>
</template>

<script>
export default {
  props: {
    index: {
      default: 1
    },
    size: {
      default: 10
    },
    total: {
      default: -1
    },
    maxSize: {
      default: 10
    },
    label: {
      default: "第 {index}/{count} 页 (共 {total} 条记录)"
    }
  },
  data() {
    return {
      // buttons: []
    };
  },
  computed: {
    count() {
      return Math.max(0, Math.ceil(this.total / this.size));
    },
    buttons() {
      let buttons = [],
        maxSize = parseInt(this.maxSize + ((this.maxSize % 2) - 1)),
        start = 1,
        end = 10;
      start = Math.max(1, this.index - Math.floor(maxSize / 2));
      end = Math.min(start + maxSize, this.count + 1);
      let surplus = maxSize - end + start;
      if (surplus) start = Math.max(1, start - surplus);
      for (var i = start; i < end; i++) buttons.push(i);
      return buttons;
    }
  },
  methods: {
    go(i) {
      this.$emit("change", Math.max(Math.min(i, this.count), 1));
    },
    prev() {
      this.go(this.index - 1);
    },
    next() {
      this.go(this.index + 1);
    }
  }
};
</script>

<style lang="less">
.v-pagination {
  display: flex;
  display: flex;
  margin: 24px 0px;
  align-items: center;
  justify-content: space-between;
  > .btns {
    user-select: none;
    > .btn {
      cursor: pointer;
      min-width: 24px;
      color: #666666;
      line-height: 24px;
      border-radius: 2px;
      text-align: center;
      display: inline-block;
      border: 1px solid #dddddd;
      background-color: #ffffff;
      transition: 300ms;
      &:hover {
        color: #349e69;
        border-color: #349e69;
      }
      &.go {
        margin: 0px 1px;
        &.active {
          color: #ffffff;
          border-color: #349e69;
          background-color: #349e69;
        }
      }
    }
  }
  &[sticky] {
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: sticky;
    // border: 1px solid #dddddd;
    background-color: #ffffff;
    // background-color: rgba(255, 255, 255, 0.6);
    // box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
}
</style>
