/**
 * Event扩展
 */
Object.assign(Event, {
	/**
	 * 创建事件
	 * @param {string} name 事件名称
	 * @return {Event} 返回创建的事件
	 */
	create(name) {
		return document.createEvent(name);
	}
});

/**
 * ProgressEvent属性扩展
 */
Object.defineProperties(ProgressEvent.prototype, {
	value: {
		get() {
			return this.lengthComputable ? this.loaded / this.total : -1;
		}
	}
}); 