/**
 * Window原型扩展
 */
Object.assign(Window.prototype, {
	/**
	 * 整数
	 */
	Integer(o) {
		return parseInt(o) || 0;
	}
})

/**
 * Number扩展
 */
Object.assign(Number, {
	/**
	 * 数值补零
	 * @param {number|string} value 值
	 * @param {number} length 总长度
	 * @return {string} 返回补零后的数值字符串
	 */
	fillZero(value = 0, length = 0) {
		return (Array(length).join('0') + value).slice(-length);
	},
	/**
	 * 限制小数位长度
	 * @param {*} value 值
	 * @param {number} length 数值小数位长度
	 * @return {string} 返回处理小数位数后的值
	 */
	decimal(value = 0, length = 2, mode = true) {
		return window[mode ? 'Number' : 'String']((Number(value) || 0).toFixed(length));
	}
});