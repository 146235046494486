<template>
	<v-popover class="v-select" trigger="click" ref="input" :keep-active="maxCount>1" :multiple="maxCount>1">
		<button class="selected-options" :id="id" :placeholder="placeholder" :disabled="$disabled" slot="trigger">
			<div class="option" v-for="(o,i) in selectedOptions" :key="i">
        <span>{{o.label}}</span>
        <i class="ss-passport icon-remove2" @click="remove(o.value)"/>
      </div>
		</button>
		<slot/>
	</v-popover>
</template>

<script>
export default {
  props: {
    value: {
      default() {
        return [];
      }
    },
    id: {
      default: null
    },
    placeholder: {
      default: "请选择"
    },
    multiple: {
      default: 1
    }
  },
  data() {
    return {
      options: []
    };
  },
  computed: {
    maxCount() {
      return Math.max(1, this.multiple || Infinity);
    },
    selectedOptions() {
      return (isArray(this.value)
        ? this.value
        : isEmpty(this.value)
        ? []
        : [this.value]
      )
        .map(value => this.options.filter(o => o.value === value)[0])
        .filter(o => o);
    },
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        if (this.value != value) {
          this.$emit("change", value);
        }
      }
    }
  },
  methods: {
    remove(value) {
      if (this.maxCount > 1) {
        const selected = this.selected,
          index = selected.indexOf(value);
        index == -1 || selected.splice(index, 1);
        this.selected = selected;
        console.log(this.selected);
      } else {
        this.selected = null;
      }
    }
  },
  mounted() {
    ["load-prev", "load-next"].forEach(
      k => k in this.$listeners && this.$refs.input.$on(k, this.$listeners[k])
    );
  }
};
</script>

<style lang="less">
.v-select {
  width: 264px;
  display: inline-flex;
  flex-direction: column;
  &[multiple] {
    > .selected-options {
      padding-left: 2px;
      &:empty {
        padding-left: 5px;
      }
      > .option {
        margin: 1px;
        padding: 0px 5px;
        border-radius: 3px;
        background-color: #e8e8e8;
        > i {
          display: inline-block;
        }
      }
    }
  }
  &.active {
    > .selected-options {
      border-color: #349e69;
      &::after {
        transform: translate3d(-8px, 11px, 0) rotateZ(180deg);
      }
    }
  }
  > .selected-options {
    flex: 1;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    min-height: 30px;
    text-align: left;
    user-select: none;
    line-height: 22px;
    position: relative;
    border-radius: 4px;
    padding: 2px 24px 2px 6px;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    transition: border 200ms;
    &:hover,
    &:focus {
      border-color: #349e69;
    }
    &::after {
      top: 0px;
      right: 0px;
      width: 0px;
      height: 0px;
      content: "";
      position: absolute;
      border-style: solid;
      border-width: 6px 5px 0px 5px;
      border-color: #999999 transparent transparent transparent;
      transform: translate3d(-8px, 12px, 0) rotateZ(0deg);
      transition: transform 200ms;
    }
    > .option {
      display: inline-block;
      word-break: break-word;
      > i {
        display: none;
        cursor: pointer;
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
}
</style>