<template>
	<div class="v-date-range-panel" ref="input">
		<v-date-panel class="begin" :type="type" :min-date="minDate" :max-date="maxDate" :format="format" :begin="range[0]" :end="range[1]" :hover.sync="hover" v-model="model" ref="begin"/>
		<v-date-panel class="end" :type="type" :min-date="minDate" :max-date="maxDate" :format="format" :begin="range[0]" :end="range[1]" :hover.sync="hover" v-model="model" ref="end"/>
	</div>
</template>

<script>
export default {
  props: {
    value: {
      default() {
        return [];
      }
    },
    type: {
      default: "text",
      validator(value) {
        return ["text", "timestamp"].includes(value);
      }
    },
    minDate: {
      default: -Infinity
    },
    maxDate: {
      default: Infinity
    },
    format: {
      default: "yyyy-MM-dd"
    }
  },
  data() {
    return {
      hover: null,
      range: (isArray(this.value) ? this.value : []).filter(o => o)
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        if (this.range.length == 2) {
          this.range.length = 0;
        }
        if (this.range.length < 2) {
          this.range.push(value);
        }
        if (this.range.length == 2) {
          this.$emit(
            "input",
            this.range.sort((a, b) => (new Date(a) < new Date(b) ? -1 : 1))
          );
          this.$emit("close");
        }
      }
    }
  },
  methods: {
    init() {
      this.$refs.begin.init(this.model[0]);
      this.$refs.end.init(this.model[1]);
    }
  }
};
</script>

<style lang="less">
.v-date-range-panel {
  display: flex;
  > .v-date-panel {
    &.begin {
      border-right: 1px solid #dddddd;
    }
  }
}
</style>