<template>
	<v-popover class="v-cascader" :class="{loading}" trigger="click" ref="input" @open="$emit('open',selected)" keep-active>
		<button :id="id" class="selected" slot="trigger" :placeholder="placeholder">{{label}}</button>
		<template>
      <div class="options" v-for="(items,i) in selectedOptions" :key="i" v-scrollbar>
        <div class="option" :class="{selected:selected[i]&&selected[i][optionValue]==o[optionValue]}" v-for="(o,j) in items" :key="j"  @click="select(o,i)">{{o[optionLabel]}}</div>
      </div>
		</template>
	</v-popover>
</template>

<script>
export default {
  props: {
    value: {
      default() {
        return [];
      }
    },
    id: {
      default: null
    },
    options: {
      default() {
        return [];
      }
    },
    optionRoot: {
      default: null
    },
    optionParent: {
      default: "parent"
    },
    optionLabel: {
      default: "label"
    },
    optionValue: {
      default: "value"
    },
    optionLevel: {
      default: Infinity
    },
    placeholder: {
      default: "请选择"
    },
    spliter: {
      default: "/"
    }
  },
  data() {
    return {
      loading: false,
      selected: (this.value || []).filter(o => o),
      selectedOptions: []
    };
  },
  computed: {
    children() {
      return this.selectedOptions.reduce((p, c) => p.concat(c), this.options);
    },
    label() {
      return this.value
        .map(
          v =>
            (this.children.filter(o => o[this.optionValue] == v)[0] || {
              [this.optionLabel]: ""
            })[this.optionLabel]
        )
        .join(this.spliter);
    }
  },
  watch: {
    ["options"]() {
      this.reset();
    }
  },
  methods: {
    async getChildren(p) {
      let children = [];
      if (this.options.length && this.selected.length < this.optionLevel) {
        if ("remote" in this.$listeners) {
          children = await this.$listeners.remote(
            p,
            this.selected.length - 1,
            this.selected
          );
        } else {
          children = this.options.filter(o => o[this.optionParent] == p);
        }
      }
      return children || [];
    },
    async reset() {
      this.selected = [];
      this.selectedOptions = [await this.getChildren(this.optionRoot)];
      for (const v of this.value.filter(v => v)) {
        const option = this.children.filter(o => o[this.optionValue] == v)[0];
        if (option) {
          this.selected.push(option);
          const children = await this.getChildren(v);
          if (children.length) {
            this.selectedOptions.push(children);
          }
        }
      }
      this.$emit("open", this.selected);
    },
    async select(o, i) {
      this.loading = true;
      this.selected.length = i;
      this.selected.push(o);
      const list = await this.getChildren(o[this.optionValue]);
      this.selectedOptions.length = i + 1;
      if (list.length) {
        this.selectedOptions.push(list);
      } else {
        this.$emit("input", this.selected.map(x => x[this.optionValue]));
        this.$emit("change", this.selected);
        this.$refs.input.close();
      }
      this.loading = false;
    }
  },
  created() {
    this.reset();
  }
};
</script>

<style lang="less">
.v-cascader {
  width: 264px;
  display: inline-flex;
  flex-direction: column;
  &.active {
    > .selected {
      border-color: #3e946a;
      &::after {
        border-top-color: #3e946a;
        transform: translate3d(-8px, 12px, 0) rotateZ(180deg);
      }
    }
  }
  &.loading {
    > .content {
      &:before,
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  > .selected {
    outline: none;
    cursor: pointer;
    font-size: 14px;
    text-align: left;
    min-height: 32px;
    line-height: 24px;
    position: relative;
    border-radius: 4px;
    padding: 3px 24px 3px 6px;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    transition: border 300ms;
    &:hover,
    &:focus {
      border-color: #3e946a;
    }
    &[context]:empty::before {
      color: #333333;
      content: attr(context);
    }
    &::after {
      top: 0px;
      right: 0px;
      width: 0px;
      height: 0px;
      content: "";
      position: absolute;
      border-style: solid;
      border-width: 6px 6px 0px 6px;
      border-color: #999999 transparent transparent transparent;
      transform: translate3d(-8px, 13px, 0) rotateZ(0deg);
      transition: transform 300ms;
    }
  }
  > .content {
    display: flex;
    min-width: auto;
    overflow: hidden;
    &:before {
      z-index: 1;
      opacity: 0;
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      visibility: hidden;
      background-color: rgba(255, 255, 255, 0.6);
      transition: 200ms;
    }
    &:after {
      top: 50%;
      left: 50%;
      z-index: 2;
      opacity: 0;
      content: "";
      width: 30px;
      height: 30px;
      display: block;
      border-width: 3px;
      position: absolute;
      visibility: hidden;
      border-radius: 50%;
      border-style: solid;
      border-color: #3e946a transparent #3e946a transparent;
      transform: translate3d(-50%, -50%, 0);
      transition: 200ms;
      animation: loading 600ms linear infinite;
    }
    > .options {
      max-height: 100%;
      &:not(:last-child) {
        border-right: 1px solid #dddddd;
      }
      > .option {
        margin: 2px 0px;
        cursor: pointer;
        min-width: 100px;
        padding: 5px 10px;
        transition: 300ms;
        user-select: none;
        white-space: nowrap;
        &:hover {
          background-color: #f0f0f0;
        }
        &.selected {
          top: 0px;
          bottom: 0px;
          color: #ffffff;
          position: sticky;
          background-color: #3e946a;
        }
      }
    }
  }
}
</style>