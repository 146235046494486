<template>
  <div class="v-app">
    <router-view/>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
.v-app {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
