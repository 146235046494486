
export default [
	{
		path: '/',
		redirect: '/login',
		component: () => import('@/views'),
		children: {
			register: [
				{
					name: '用户注册',
					path: 'register',
					component: () => import('@/views/register'),
				}
			],
			passport: [
				{
					name: '重置密码',
					path: 'reset',
					component: () => import('@/views/reset'),
				},
				{
					name: '机构版',
					path: 'login',
					component: () => import('@/views/login'),
				},
				{
					name: '个人版',
					path: 'login/personal',
					component: () => import('@/views/login/personal'),
				}
			],
			visa: [
				{
					name: '用户登录',
					path: 'login',
					component: () => import('@/views/login/operate'),
				}
			],
			/*开发环境*/
			'7001': [
				{
					name: '机构版',
					path: 'login',
					component: () => import('@/views/login'),
				},
				{
					name: '个人版',
					path: 'login/personal',
					component: () => import('@/views/login/personal'),
				},
				{
					name: '用户注册',
					path: 'register',
					component: () => import('@/views/register'),
				},
				{
					name: '重置密码',
					path: 'reset',
					component: () => import('@/views/reset'),
				},
				{
					name: '用户登录',
					path: 'login/operate',
					component: () => import('@/views/login/operate'),
				}
			]
		}[(location.port || location.hostname.replace(/\..*/, ''))]
	},
	{
		name: '消费端用户注册',
		path: '/register/consumer',
		component: () => import('@/views/register/consumer'),
	},
	{
		name: '消费端用户注册异常页面',
		path: '/register/consumer/error',
		component: () => import('@/views/register/consumer/error'),
	},
	{
		name: () => "登录中...",
		path: '/login/consumer',
		component: () => import('@/views/login/consumer'),
	},
	{
		name: '消费端用户登录异常页面',
		path: '/login/consumer/error',
		component: () => import('@/views/login/consumer/error'),
	},
	{
		name: '大医同盟',
		path: '/login/cnlod',
		component: () => import('@/views/login/cnlod'),
	}
];