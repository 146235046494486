
/**
 * Object扩展
 */
Object.assign(Object, {
	/**
	 * 克隆对象
	 * @param {*} target 目标对象
	 * @return {*} 返回克隆的对象
	 */
	clone(target) {
		return JSON.parse(JSON.stringify(target));
	},
	/**
	 * 清理对象
	 * @param {Object|Array} target 目标对象
	 * @param {Array} match 匹配需要清理的值
	 * @return {Object|Array} 返回清理后的对象
	 */
	clean(target = {}, match = [undefined, null]) {
		return Object.entries(target).reduce((p, [k, v]) => {
			return ['Object', 'Array'].includes(typeOf(v))
				? Object.clean(v, match)
				: match.includes(v) && delete target[k]
				, p;
		}, target);
	},
	/**
	 * 展开对象
	 * @param {Object|Array} target 目标对象
	 * @param {string} parent 父级key
	 * @return {Object|Array} 返回展开后的对象
	 */
	unfold(target, parent) {
		if (['Object', 'Array'].includes(typeOf(target))) {
			return Object.entries(target).reduce((p, [k, v]) => {
				let temp = (isArray(target) || !(/^\w+$/gm).test(k)) ? '[$1]' : '.$1',
					key = parent ? `${parent}${k.replace(/^(.*)$/gm, temp)}` : k,
					value = Object.unfold(v, key);
				if (typeOf(value) == 'Object') {
					Object.assign(p, value);
				} else {
					p[key] = value;
				}
				return p;
			}, {});
		} else {
			return target;
		}
	},
	/**
	 * 表单验证
	 * @param {*} target 目标对象
	 * @param {string|Object|Array} option 配置选项
	 * @return {Promise} 返回实例回调
	 */
	validate: Object.assign(async function (target, option = []) {
		let message = null;
		const rules = (isArray(option) ? option : [option]).map(rule => {
			return {
				'String': { required: true, message: rule },
				'Function': { validate: rule }
			}[typeOf(rule)] || rule;
		});
		for (let rule of rules) {
			for (let [k, r] of Object.entries(rule)) {
				if (k in this.validate.rules) {
					await this.validate.rules[k](target, r, (v, m) => {
						v || message || (message = m || rule.message);
					});
				}
			}
		}
		return message;
	}, {
			rules: {
				async validate(value, param, next) {
					isFunction(param) && await param(value, next);
				},
				required(value, param, next) {
					next(!param || !isBlank(value));
				},
				type(value, param, next) {
					if (isBlank(value)) {
						next(true);
					} else {
						switch (param) {
							case Boolean:
								next(isBoolean(value)); break;
							case Integer:
								next(isInteger(value)); break;
							case Number:
								next(isNumber(value)); break;
							case String:
								next(isString(value)); break;
							case Date:
								next(isDate(value)); break;
							case RegExp:
								next(isRegExp(value)); break;
							case Function:
								next(isFunction(value)); break;
							case Array:
								next(isArray(value)); break;
							case Object:
								next(isObject(value)); break;
							case 'integer':
								next(isInteger(parseFloat(value))); break;
							case 'number':
								next(isNumber(parseFloat(value))); break;
							case 'date':
								next(new Date(value) != 'Invalid Date'); break;
							default:
								next(true); break;
						}
					}
				},
				not(value, param, next) {
					next(isBlank(value) || value !== param);
				},
				eq(value, param, next) {
					next(isBlank(value) || value === param);
				},
				lt(value, param, next) {
					next(isBlank(value) || value < param);
				},
				gt(value, param, next) {
					next(isBlank(value) || value > param);
				},
				min(value, param, next) {
					next(isBlank(value) || value >= param);
				},
				max(value, param, next) {
					next(isBlank(value) || value <= param);
				},
				length(value, param, next) {
					next(isBlank(value) || isNull(value.length) || value.length == param);
				},
				minLength(value, param, next) {
					next(isBlank(value) || isNull(value.length) || value.length >= param);
				},
				maxLength(value, param, next) {
					next(isBlank(value) || isNull(value.length) || value.length <= param);
				},
				regexp(value, param, next) {
					next(isBlank(value) || isRegExp(param) && param.test(value));
				},
				includes(value, param, next) {
					next(isBlank(value) || isFunction(value.includes) && value.includes(param));
				},
				enum(value, param, next) {
					next(isBlank(value) || isFunction(param.includes) && param.includes(value));
				},
				each(value, param, next) {
					next(isBlank(value) || [...value].reduce((p, o, i, a) => p && param(o, i, a), true));
				}
			}
		})
});