/**
 * Window原型扩展
 */
Object.assign(Window.prototype, {
	/**
	 * Jsonp(不支持缓存)
	 * @param {string} url 请求地址
	 * @param {Object} data 请求数据
	 * @return {Promise} 返回Promise对象
	 */
	jsonp: Object.assign(function (url, data) {
		return new Promise((resolve, reject) => {
			let o = URL.parse(url),
				key = `$${String.random.uuid(8)}`,
				clear = timer => {
					clearTimeout(timer);
					if (key in jsonp) {
						jsonp[key].target.remove();
						delete jsonp[key];
					}
				},
				timer = setTimeout(() => {
					reject({ type: 'timeout' }), clear(timer);
				}, jsonp.timeout);
			jsonp[key] = Object.assign(function (o) {
				resolve(o), clear(timer);
			}, {
					target: document.head.appendChild(Object.assign(Element.create('script'), {
						src: `${o.origin}${o.pathname}?${jsonp.callbackName}=jsonp.${key}&${URL.toSearch(Object.assign(URL.toQuery(o.search), data))}`
					}))
				});
		});
	}, {
			/**
			 * JSONP回调名称
			 */
			callbackName: 'callback',
			/**
			 * JSONP超时时长
			 */
			timeout: 30000
		})
})