/**
 * Image原型扩展
 */
Object.assign(Image.prototype, {
	/**
	 * 转换到Base64字符串
	 * @param {string} type 图片类型
	 * @return {string} 返回转换后的的字符串
	 */
	toBase64(type) {
		let { naturalWidth, naturalHeight } = this,
			canvas = Object.assign(Element.create('canvas'), {
				width: naturalWidth,
				height: naturalHeight
			});
		canvas.getContext('2d').drawImage(this, 0, 0, naturalWidth, naturalHeight);
		return canvas.toDataURL(type);
	}
});