import "pro";

import Vue from "vue";

import "@/css/index.less";
import App from "@/app";

const env = (Vue.prototype.$env = location.port
  ? {
    from: location.hostname.match(/^([^.]+)/)[0],
    origin: "",
    login: "/login/personal",
    register: "/register",
  }
  : {
    from: location.hostname.match(/^([^.]+)/)[0],
    origin: `//${location.hostname.replace(/^\w*\./gm, "")}`,
    login: `//${location.hostname.replace(/^\w*/gm, "passport")}/login/personal`,
    register: `//${location.hostname.replace(/^\w*/gm, "register")}/register`,
  });

import Directives from "@/directives";
Vue.use(Directives);

import Components from "@/components";
Vue.use(Components);

import Loader from "@/loader";
Vue.use(Loader, {
  async load({ router, apis }) {
    router.beforeEach((to, from, next) => {
      document.title = to.name instanceof Function ? to.name() : "善识健康" + (to.name || "").replace(/(.+)/, " - $1");
      next();
    });

    router.beforeResolve((to, from, next) => {
      next();
    });

    apis.request((req) => {
      return Object.assign({}, req, {
        url: `${{
          dev: {
            "/reset": `//api.5xingfu.com:8082`,
            "/register": `//api.5xingfu.com:8080`,
            "/login": `//passport.5xingfu.com`,
            "/login/personal": `//api.5xingfu.com:8082`,
            "/login/operate": `//api.5xingfu.com:8083`,
            "/login/cnlod": `//api.5xingfu.com:8082`,
          }[location.pathname],
        }[env.from] || ""}${req.url}`,
      });
    });

    apis.response((res) => {
      if (res instanceof Error) {
        Vue.prototype.$message.warn("正在请求中，请稍后...");
        throw res;
      } else if (res.success) {
        return res.result;
      } else if (res.code == "300101") {
        const { origin } = URL.toQuery(location.search);
        location.replace(origin);
      } else if (["300102", "300205"].includes(res.code)) {
        const { origin } = URL.toQuery(location.search);
        location.replace(location.origin.replace("passport", "register") + `/litchi-server/oauth/wechat/web-authorize?${origin}`);
      } else {
        res.msg && Vue.prototype.$message.error(res.msg.replace(/;/gm, "\n"));
        throw res;
      }
    });

    Vue.prototype.$apis = apis;

    new Vue(Object.assign(App, { router })).$mount("#app");
  },
});
