<template>
	<div class="v-popover" :class="{active}">
		<slot name="trigger"/>
		<div class="content" v-loading="loading" ref="content" v-scrollbar>
			<slot/>
		</div>
	</div>
</template>

<script>
const popovers = [];
export default {
  props: {
    trigger: {
      default: "",
      validator(v) {
        return ["", "click", "hover"].includes(v);
      }
    }
  },
  data() {
    return {
      top: false,
      left: false,
      active: false,
      loading: false
    };
  },
  computed: {
    keepActive() {
      return (
        ("keep-active" in this.$attrs &&
          isString(this.$attrs["keep-active"])) ||
        this.$attrs["keep-active"]
      );
    }
  },
  methods: {
    open() {
      if (this.active) {
        window.emit("click");
      } else {
        this.active = true;
        this.$emit("open");
        this.$refs.content.scrollTop = 0;
        popovers.forEach(p => p == this || p.close());
      }
    },
    close() {
      if (this.active) {
        this.active = false;
        this.$emit("close");
      }
    },
    remove() {
      let index = popovers.indexOf(this);
      index == -1 || popovers.splice(index, 1);
    }
    // setPosition() {
    //   if (this.$refs.content) {
    //     let { innerWidth, innerHeight } = window,
    //       { offsetWidth, offsetHeight } = this.$refs.content,
    //       { top, left, right, bottom } = this.$el.getBoundingClientRect();
    //     (right = innerWidth - right), (bottom = innerHeight - bottom);
    //     this.top = bottom < offsetHeight && top > offsetHeight;
    //     this.left = right < this.$el.offsetWidth - offsetWidth;
    //   }
    // },
    // scrollBottom() {
    //   if ("load-next" in this.$listeners) this.loading = true;
    //   this.$emit("load-next", () => (this.loading = false));
    // }
  },
  mounted() {
    popovers.push(this);
    let type = {
        click: "click",
        hover: "mouseenter"
      }[this.trigger],
      { content } = this.$refs;
    if (type) {
      (this.$slots.trigger || []).forEach(slot => {
        slot.elm.on(type, e => {
          if (!this.$disabled) {
            e.stopPropagation(), this.open();
          }
        });
        if (type == "mouseenter") {
          slot.elm.on("click", e => this.$disabled || e.stopPropagation());
        }
      });
      if (type == "mouseenter") {
        content.on("mouseleave", this.close);
      }
      content.on("click", e => {
        this.keepActive && e.stopPropagation();
      });
    }
    content.on("scroll", e => {
      let { scrollTop, scrollHeight, clientHeight } = e.target;
      if (scrollTop == 0) {
        if ("load-prev" in this.$listeners || "load-prev" in this._events) {
          this.loading = true;
          this.$emit("load-prev", () => {
            this.loading = false;
            setTimeout(content.initScrollbar);
          });
        }
      } else if (scrollTop >= scrollHeight - clientHeight) {
        if ("load-next" in this.$listeners || "load-next" in this._events) {
          this.loading = true;
          content.initScrollbar();
          this.$emit("load-next", () => {
            this.loading = false;
            setTimeout(content.initScrollbar);
          });
        }
      } else {
        this.$emit("scroll", e);
      }
    });
    window.on("click", this.close);
  },
  destroyed() {
    this.remove();
    window.off("click", this.close);
  }
};
</script>

<style lang="less">
.v-popover {
  position: relative;
  display: inline-flex;
  &.active {
    > .content {
      z-index: 2;
      opacity: 1;
      visibility: visible;
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    }
  }
  > .content {
    top: 100%;
    z-index: 1;
    opacity: 0;
    overflow: auto;
    min-width: 100%;
    margin-top: 5px;
    max-height: 300px;
    position: absolute;
    visibility: hidden;
    border-radius: 4px;
    line-height: initial;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    transform: translate3d(0, -50%, 0) scale3d(1, 0, 1);
    transition: 300ms;
    &.nodata::after {
      display: block;
      color: #999999;
      padding: 5px 10px;
      content: "暂无数据";
    }
  }
}
</style>
