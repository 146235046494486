/**
 * Proxy扩展
 */
Object.assign(Proxy, {
	/**
	 * 深度代理
	 * @param {Object} target 目标对象
	 * @param {Object} o 操作对象
	 * @param {Function} e 操作回调
	 * @return {Object|Proxy} 返回代理对象(目标对象不是Object类型则返回源对象) 
	 */
	deep(target, o, e) {
		return isObject(target)
			? (target = new Proxy(target, {
				get: (t, p) => Proxy.deep(
					e ? t[p] : o.get(t, p),
					e ? { set: (t, p, v) => t[p] = v } : o,
					v => target[p] = v
				),
				set: (t, p, v) => {
					o.set(t, p, v);
					e && e(t, p, v);
					return true;
				}
			}))
			: target;
	}
});