/**
 * Date扩展
 */
Object.assign(Date, {
	/**
	 * 天
	 */
	Day: 86400000,
	/**
	 * 年
	 */
	Year: 31557600000,
	/**
	 * 格式化
	 * @param {number|string|Date} value 日期值
	 * @param {string|Function} exp 表达式
	 * @return {string} 返回格式化后的日期字符串
	 */
	format(value, exp = 'date time') {
		if (isString(value)) {
			value = value.replace(/-/gm, '/');
		}
		if (!isBlank(value) && isDate(value = new Date(value))) {
			var y = value.getFullYear().toString(),
				M = value.getMonth() + 1,
				d = value.getDate(),
				w = value.getDay(),
				H = value.getHours(),
				h = H > 12 ? H - 12 : H,
				t = H < 12 ? 'AM' : 'PM',
				m = value.getMinutes(),
				s = value.getSeconds(),
				ms = value.getMilliseconds();
			return ({
				'Function': () => exp({ y, M, d, H, h, m, s, ms, w }),
				'String': () => exp.replace(/auto/, () => {
					let date = new Date();
					if (d == date.getDate()) {
						return 'HH:mm';
					} else if (y == date.getFullYear()) {
						return 'MM-dd';
					} else {
						return 'yyyy-MM';
					}
				})
					.replace(/date/, 'yyyy-MM-dd')
					.replace(/time/, 'HH:mm:ss')
					.replace(/yyyy/, y)
					.replace(/yy/, y.substring(2))
					.replace(/MM/, Number.fillZero(M, 2))
					.replace(/M/, M)
					.replace(/dd/, Number.fillZero(d, 2))
					.replace(/d/, d)
					.replace(/w/, w)
					.replace(/W/, ['日', '一', '二', '三', '四', '五', '六'][w])
					.replace(/HH/, Number.fillZero(H, 2))
					.replace(/H/, H)
					.replace(/hh/, Number.fillZero(h, 2))
					.replace(/h/, h)
					.replace(/t/, t)
					.replace(/T/, { AM: '上', PM: '下' }[t])
					.replace(/zms/, ms)
					.replace(/ms/, Number.fillZero(ms, 3))
					.replace(/mm/, Number.fillZero(m, 2))
					.replace(/m/, m)
					.replace(/ss/, Number.fillZero(s, 2))
					.replace(/s/, s)
			}[typeOf(exp)]())
		}
	},
	/**
	 * 年龄 (周岁)
	 * @param {number|string} value 值
	 * @return {string} 返回格式化后的日期字符串
	 */
	age(value) {
		return Math.max(0, parseInt((Date.today() - Date.today(value)) / (365.25 * 24 * 60 * 60 * 1000))) || null;
	},
	/**
	 * 当年
	 * @param {number|string} value 值
	 * @return {Date} 返回当年的日期对象
	 */
	toyear(value = Date.now()) {
		return new Date(Date.format(value, 'yyyy/01/01'));
	},
	/**
	 * 当月
	 * @param {number|string} value 值
	 * @return {Date} 返回当月的日期对象
	 */
	tomonth(value = Date.now()) {
		return new Date(Date.format(value, 'yyyy/MM/01'));
	},
	/**
	 * 当天
	 * @param {number|string} value 值
	 * @return {Date} 返回当天的日期对象
	 */
	today(value = Date.now()) {
		return new Date(Date.format(value, 'yyyy/MM/dd'));
	}
});

/**
 * Date原型扩展
 */
Object.assign(Date.prototype, {
	/**
	 * 格式化
	 * @param {string|Function} exp 表达式
	 * @return {string} 返回格式化后的日期字符串
	 */
	format(exp) {
		return Date.format(this, exp);
	},
	/**
	 * 当年
	 * @param {number|string} value 值
	 * @return {Date} 返回当年的日期对象
	 */
	toyear() {
		return Date.toyear(this);
	},
	/**
	 * 当月
	 * @param {number|string} value 值
	 * @return {Date} 返回当月的日期对象
	 */
	tomonth() {
		return Date.tomonth(this);
	},
	/**
	 * 当天
	 * @param {number|string} value 值
	 * @return {Date} 返回当天的日期对象
	 */
	today() {
		return Date.today(this);
	}
});