import apis from "@/apis";
import routes from "@/routes";

import VueRouter from "vue-router";

export default {
  install(
    Vue,
    { mode = "history", scrollBehavior = (to, from, savedPosition) => (to.hash ? { selector: to.hash } : savedPosition || { x: 0, y: 0 }), load = () => {} }
  ) {
    function init(apis, parent) {
      return apis.map((api) =>
        Object.assign(
          Object.defineProperties(api, {
            parent: {
              get() {
                return parent;
              },
            },
            path: {
              get() {
                let paths = [],
                  p = this;
                while (p) {
                  p.name == null || paths.unshift(p.name);
                  p = p.parent;
                }
                return `/${paths.join("/")}`;
              },
            },
          }),
          {
            children: init(api.children || [], api),
          }
        )
      );
    }

    Vue.use(VueRouter);

    let request = (req, next) => next(req),
      response = (res, next) => next(res);

    load({
      router: new VueRouter({
        mode,
        routes,
        scrollBehavior,
      }),
      apis: Object.defineProperties(
        init(apis)
          .toFlatted({ parentKey: "parentKey" })
          .reduce((p, { name, method, url, path }) => {
            p[path] = async (data, option) => {
              option = await request(Object.assign({ name, path, method, url, data }, option));
              if (option.data) {
                const matchers = option.url.match(/{.+?}/gm) || [];
                option.url = String.template(option.url, option.data);
                matchers.forEach((k) => delete option.data[k.replace(/{(.+?)}/gm, "$1")]);
              }
              return await response(await new XMLHttpRequest().request(option));
            };
            return p;
          }, {}),
        {
          request: {
            value(cb) {
              request = cb;
            },
          },
          response: {
            value(cb) {
              response = cb;
            },
          },
        }
      ),
    });
  },
};
