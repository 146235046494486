<template>
	<div class="v-scaler">
    <div :style="{paddingTop}"></div>
		<div class="content" ref="content">
      <slot/>
    </div>
	</div>
</template>

<script>
export default {
  props: {
    scale: {
      default: 1
    }
  },
  computed: {
    paddingTop() {
      return String.unit(this.scale * 100, "%");
    }
  },
  mounted() {
    this.$refs.content.ons(this.$listeners);
  }
};
</script>

<style lang="less">
.v-scaler {
  position: relative;
  > .content {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
    position: absolute;
  }
}
</style>
