/**
 * Screen属性扩展 
 */
Object.defineProperties(Screen.prototype, {
	/**
	 * DPI
	 */
	dpi: {
		get() {
			let el = document.body.appendChild(Element.create('div'));
			Object.assign(el.style, { width: '1in' });
			let width = el.offsetWidth;
			el.remove();
			return width;
		}
	}
});