/**
 * 滚动条指令
 */
export default {
	update(el) {
		el.classList.contains('v-scrollbar') ||
			el.classList.add('v-scrollbar');
	},
	inserted(el, e) {
		el.classList.add('v-scrollbar');
		let point = { top: 0, left: 0 },
			h = Element.create('div'),
			v = Element.create('div');
		h.classList.add('v-scrollbar-horizontal');
		v.classList.add('v-scrollbar-vertical');
		el.insertBefore(v, el.firstElementChild);
		el.insertBefore(h, el.firstElementChild);
		if (e.modifiers.inner) {
			el = el.children[e.value + 2];
		}
		h.on("dragmove", {
			start: () => {
				point.left = el.scrollLeft;
			},
			move: ({ offsetX, target }) => {
				el.scrollLeft =
					point.left +
					(offsetX / (el.clientWidth - target.clientWidth)) *
					(el.scrollWidth - el.clientWidth);
			}
		});
		v.on("dragmove", {
			start: () => {
				point.top = el.scrollTop;
			},
			move: ({ offsetY, target }) => {
				el.scrollTop =
					point.top +
					(offsetY / (el.clientHeight - target.clientHeight)) *
					(el.scrollHeight - el.clientHeight);
			}
		});
		el.initScrollbar = x => {
			let {
				scrollTop,
				scrollLeft,
				scrollWidth,
				scrollHeight,
				clientWidth,
				clientHeight
			} = el,
				horizontal = {
					size: scrollWidth - clientWidth,
					width: (clientWidth / scrollWidth) * clientWidth,
					get show() {
						return this.size > 0;
					}
				},
				vertical = {
					size: scrollHeight - clientHeight,
					height: (clientHeight / scrollHeight) * clientHeight,
					get show() {
						return this.size > 0;
					}
				};
			Object.assign(h.style, {
				display: horizontal.show ? "initial" : "none",
				width: `${horizontal.width}px`,
				transitionProperty: x ? 'width,background-color' : '',
				transform: `translate3d(${(e.modifiers.inner ? 0 : scrollLeft) +
					(scrollLeft / horizontal.size) *
					(clientWidth -
						horizontal.width -
						(vertical.show ? 12 : 6))}px,${scrollTop}px,0)`
			});
			Object.assign(v.style, {
				display: vertical.show ? "initial" : "none",
				height: `${vertical.height}px`,
				transitionProperty: x ? 'width,background-color' : '',
				transform: `translate3d(${scrollLeft}px,${(e.modifiers.inner ? 0 : scrollTop) +
					(scrollTop / vertical.size) *
					(clientHeight -
						vertical.height -
						(horizontal.show ? 12 : 6))}px,0)`
			});
		};
		el.ons({
			scroll: el.initScrollbar,
			mouseenter: el.initScrollbar,
			wheel: ({ deltaX, deltaY }) => {
				if (browser.type == "Firefox") {
					/*todo Firefox需要优化成异步滚动*/
					el.scrollTop += deltaY * (100 / 3);
					el.scrollLeft += deltaX * (100 / 3);
				}
			}
		});
	}
}  