<template>
	<v-cascader class="v-select-region" :id="id" :placeholder="placeholder" :options="options" option-root="86" option-parent="p" option-label="n" option-value="c" :option-level="level" v-model="model"/>
</template>

<script>
import options from "./data.json";
export default {
  props: {
    value: {
      default() {
        return [];
      }
    },
    id: {
      default: null
    },
    placeholder: {
      default: "请选择"
    },
    prov: {
      default: null
    },
    city: {
      default: null
    },
    area: {
      default: null
    }
  },
  data() {
    return {
      options,
      level: Infinity
    };
  },
  computed: {
    model: {
      get() {
        return [this.prov, this.city, this.area].filter(o => o);
      },
      set([prov, city, area]) {
        this.$emit("update:prov", prov);
        this.$emit("update:city", city);
        this.$emit("update:area", area);
      }
    }
  },
  methods: {
    clear() {
      this.$emit("update:prov", null);
      this.$emit("update:city", null);
      this.$emit("update:area", null);
    }
  },
  created() {
    this.level =
      Object.keys(this.$listeners).filter(k =>
        ["update:prov", "update:city", "update:area"].includes(k)
      ).length || Infinity;
  }
};
</script>