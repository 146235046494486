// /**
//  * Array原型扩展
//  */
// Object.assign(Array.prototype, {
// 	/**
// 	 * 删除对象
// 	 * @param {*} target 目标对象
// 	 * @param {string|Function} filter 过滤
// 	 */
// 	async remove(target) {
// 		if (isFunction(target)) {
// 			for (let i in this) {
// 				await target(this[i]) && this.splice(i, 1);
// 			}
// 		} else {
// 			let index = this.indexOf(this);
// 			index == -1 || this.splice(index, 1);
// 		}
// 		return this;
// 	},
// 	/**
// 	 * 查询对象
// 	 * @param {Array} args 查询条件
// 	 * @return {Object} 返回查询到的对象
// 	 */
// 	search(...args) {
// 		return this.filter(o => {
// 			return args.reduce((p, a) => {
// 				return p || Object.entries(a).reduce((q, [k, v]) => {
// 					return q && (isFunction(v) ? v(o[k]) : o[k] === v);
// 				}, true);
// 			}, false);
// 		})[0];
// 	},
// 	/**
// 	 * 转换数组到对象
// 	 * @param {string} key 键
// 	 * @param {string|Function} value 值
// 	 * @return {Object} 返回转换后的对象
// 	 */
// 	toObject(key = 'key', value = 'value') {
// 		return this.reduce((p, o) => {
// 			return p[o[key]] = isFunction(value) ? value(o) : o[value], p;
// 		}, {});
// 	},
// 	/**
// 	 * 转换数组到树
// 	 * @param {Object} option 选项
// 	 * @param {*} value 值
// 	 * @return {Aarray} 返回转换后的树
// 	 */
// 	toTree(option, value) {
// 		option = Object.assign({ key: 'key', parentKey: 'parent', childrenKey: 'children', sortKey: 'sort', sort: (a, b) => (a[option.sortKey] < b[option.sortKey] ? -1 : 1) }, option);
// 		return this.filter(o => o[option.parentKey] == value).sort(option.sort).map(o => Object.assign(o, {
// 			[option.childrenKey]: this.toTree(option, o[option.key])
// 		}));
// 	},
// 	/**
// 	 * 转换树到数组
// 	 * @param {*} value 值
// 	 * @param {Object} option 选项
// 	 * @return {Aarray} 返回转换后的数组
// 	 */
// 	toFlatted(option) {
// 		option = Object.assign({ key: 'key', parentKey: 'parent', childrenKey: 'children' }, option);
// 		return this.reduce((p, o) => p.concat([o].concat(((o[option.childrenKey] || []).toFlatted(option)).map(c =>
// 			Object.assign(c, { [option.parentKey]: o[option.key] })
// 		))), []);
// 	}
// });

/**
 * Array原型扩展
 */
Object.defineProperties(Array.prototype, {
	/**
	 * 删除对象
	 * @param {*} target 目标对象
	 * @param {string|Function} filter 过滤
	 */
	remove: {
		async value(target) {
			if (isFunction(target)) {
				for (let i in this) {
					await target(this[i]) && this.splice(i, 1);
				}
			} else {
				let index = this.indexOf(this);
				index == -1 || this.splice(index, 1);
			}
			return this;
		}
	},
	/**
	 * 查询对象
	 * @param {Array} args 查询条件
	 * @return {Object} 返回查询到的对象
	 */
	search: {
		value(...args) {
			return this.filter(o => {
				return args.reduce((p, a) => {
					return p || Object.entries(a).reduce((q, [k, v]) => {
						return q && (isFunction(v) ? v(o[k]) : o[k] === v);
					}, true);
				}, false);
			})[0];
		}
	},
	/**
	 * 转换数组到对象
	 * @param {string} key 键
	 * @param {string|Function} value 值
	 * @return {Object} 返回转换后的对象
	 */
	toObject: {
		value(key = 'key', value = 'value') {
			return this.reduce((p, o) => {
				return p[o[key]] = isFunction(value) ? value(o) : o[value], p;
			}, {});
		}
	},
	/**
	 * 转换数组到树
	 * @param {Object} option 选项
	 * @param {*} value 值
	 * @return {Aarray} 返回转换后的树
	 */
	toTree: {
		value(option, value) {
			option = Object.assign({ key: 'key', parentKey: 'parent', childrenKey: 'children', sortKey: 'sort', sort: (a, b) => (a[option.sortKey] < b[option.sortKey] ? -1 : 1) }, option);
			return this.filter(o => o[option.parentKey] == value).sort(option.sort).map(o => Object.assign(o, {
				[option.childrenKey]: this.toTree(option, o[option.key])
			}));
		}
	},
	/**
	 * 转换树到数组
	 * @param {*} value 值
	 * @param {Object} option 选项
	 * @return {Aarray} 返回转换后的数组
	 */
	toFlatted: {
		value(option) {
			option = Object.assign({ key: 'key', parentKey: 'parent', childrenKey: 'children' }, option);
			return this.reduce((p, o) => p.concat([o].concat(((o[option.childrenKey] || []).toFlatted(option)).map(c =>
				Object.assign(c, { [option.parentKey]: o[option.key] })
			))), []);
		}
	}
});
