<template>
	<v-select class="v-select-options" v-model="model" @change="$emit('change',$event)" ref="input">
		<v-select-option v-for="(o,i) in options" :key="i" :label="o[optionLabel]" :value="o[optionValue]"/>
	</v-select>
</template>

<script>
export default {
  props: {
    value: {
      default: null
    },
    options: {
      default() {
        return [];
      }
    },
    optionLabel: {
      default: "label"
    },
    optionValue: {
      default: "value"
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>