<template>
	<div class="v-tree">
    <slot :level="level" :data="data" v-if="'default' in $scopedSlots"/>
    <div class="children" v-if="children.length" ref="children">
      <v-tree :label-key="labelKey" :children-key="childrenKey" v-for="(o,i) in children" :key="i" :level="level+1" :data="o" @select="$emit('select',o)">
        <slot slot-scope="{data,level}" :level="level" :data="data" v-if="'default' in $scopedSlots"/>
      </v-tree>
    </div>
	</div>
</template>

<script>
export default {
  name: "tree",
  props: {
    data: {
      default() {
        return {};
      }
    },
    level: {
      default: 0
    },
    labelKey: {
      default: "label"
    },
    childrenKey: {
      default: "children"
    }
  },
  data() {
    return {
      childrenHeight: 0
    };
  },
  computed: {
    label() {
      return this.data[this.labelKey];
    },
    children() {
      return this.data[this.childrenKey] || [];
    }
  }
};
</script>

<style lang="less">
.v-tree {
  > .item {
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>