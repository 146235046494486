<template>
	<v-upload class="v-upload-image" :class="{draging}" :multiple="rest" :maxSize="maxSize" :accept="accept" @select="select" @error="error" ref="input" v-loading="loading" draggable>
		<div class="inner" ref="inner"></div>
		<img :title="src" :src="fullSrc" v-if="multiple==1&&src&&src.length" ref="img"/>
	</v-upload>
</template>

<script>
/*todo 缺个图片预览及剪裁工具*/
export default {
  props: {
    value: {
      default() {
        return this.multiple == 1 ? "" : [];
      }
    },
    multiple: {
      default() {
        return 1;
      },
      validator(value) {
        return isInteger(value) && value >= 1;
      }
    },
    maxSize: {
      default() {
        return Infinity;
      }
    },
    origin: {
      default: ""
    }
  },
  data() {
    return {
      loading: false,
      draging: false,
      accept: "image/*",
      cache: null,
      CropDialog: this.$(() => import("@/components/dialog/crop"))
    };
  },
  computed: {
    rest() {
      return Math.max(1, this.multiple - (this.value || { length: 0 }).length);
    },
    src() {
      /^blob:/.test(this.cache) && URL.revokeObjectURL(this.cache);
      return this.value instanceof File
        ? URL.createObjectURL(this.value)
        : this.value;
    },
    fullSrc() {
      return URL.path(this.origin, this.src);
    }
  },
  methods: {
    async select(files) {
      const emit = list => {
          list.length &&
            this.$emit(
              "input",
              this.multiple == 1 ? list[0] : this.value.concat(list)
            );
        },
        resolve = async () => {
          if ("upload" in this.$listeners) {
            if (isPromise(this.$listeners.upload)) {
              this.loading = true;
              emit(await this.$listeners.upload(files));
              this.loading = false;
            } else {
              emit(this.$listeners.upload(files));
            }
          } else if (this.upload) {
            emit(await this.upload(files));
          } else {
            this.cache = this.src;
            emit(files);
          }
        };
      if (
        "crop" in this.$attrs &&
        (this.$attrs.crop === "" || this.$attrs.crop != false)
      ) {
        this.CropDialog.open(files, resolve);
      } else {
        await resolve();
      }
    },
    async upload(files) {
      let srcs = [];
      for (let file of files) {
        let {
          action,
          key,
          policy,
          signature: Signature,
          ossaccessKeyId: OSSAccessKeyId
        } = await this.$["/授权/图片"]({ fileName: file.name });
        srcs.push(
          await new Promise(resolve => {
            let req = new XMLHttpRequest();
            req.onerror = () => reject(req);
            req.onload = () => {
              if (req.status == 200) {
                resolve(key);
              } else {
                reject();
              }
            };
            req.open("POST", action);
            req.send(
              FormData.from({
                key,
                policy,
                Signature,
                OSSAccessKeyId,
                success_action_status: 200,
                file
              })
            );
          })
        );
      }
      return srcs;
    },
    async error(e) {
      if ("error" in this.$listeners) {
        if (isPromise(this.$listeners.error)) {
          this.loading = true;
          this.$emit("error", await this.$listeners.error(e));
          this.loading = false;
        } else {
          this.$listeners.error(e);
          this.$emit("error", await this.$listeners.error(e));
        }
      }
    }
  },
  mounted() {
    this.$refs.inner.ons({
      dragover: e => {
        e.preventDefault();
      },
      dragenter: e => {
        this.draging = true;
      },
      dragleave: e => {
        this.draging = false;
      },
      drop: e => {
        e.preventDefault();
        this.draging = false;
        let { multiple, maxSize, accept } = this;
        if (e.dataTransfer.files.length) {
          File.filter(e.dataTransfer.files, {
            multiple,
            maxSize,
            accept
          })
            .then(files => this.select(files))
            .catch(this.error);
        }
      }
    });
  }
};
</script>

<style lang="less">
.v-upload-image {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 2px dashed #cccccc;
  transition: 300ms;
  &:hover {
    border-color: #3e946a;
    > .inner {
      &::before,
      &::after {
        border-color: #3e946a;
      }
    }
  }
  &:-webkit-user-drag {
    background-color: red;
  }
  &.draging {
    border-color: #3e946a;
    > .inner {
      &::before,
      &::after {
        border-color: #3e946a;
      }
      &::before {
        height: 20px;
        border-width: 2px 0 2px 0;
      }
      &::after {
        width: 20px;
        border-width: 0 2px 0 2px;
      }
    }
  }
  > .inner {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: absolute;
    &::before,
    &::after {
      top: 50%;
      left: 50%;
      content: "";
      display: block;
      position: absolute;
      border-radius: 1px;
      border-style: solid;
      border-color: #cccccc;
      transform: translate3d(-50%, -50%, 0);
      transition: 300ms;
    }
    &::before {
      width: 30px;
      height: 0px;
      border-width: 1px 0 1px 0;
    }
    &::after {
      width: 0px;
      height: 30px;
      border-width: 0 1px 0 1px;
    }
  }
  > img {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: inherit;
    background-color: inherit;
    &[src=""] {
      display: none;
    }
  }
}
</style>
