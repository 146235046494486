import { reject } from "q";

const locks = [];

/**
 * XMLHttpRequest属性扩展
 */
Object.defineProperties(XMLHttpRequest.prototype, {
	setRequestHeaders: {
		value(headers) {
			Object.entries(headers).forEach(([k, v]) => this.setRequestHeader(k, v));
		}
	},
	request: {
		value(option) {
			let {
				url,
				data,
				type: responseType,
				method,
				async,
				username,
				password,
				timeout,
				credentials: withCredentials,
				headers,
				lock
			} = Object.assign({
				type: 'json',
				method: 'GET',
				async: true,
				username: null,
				password: null,
				timeout: null,
				credentials: false,
				lock: false
			}, option,
				{
					headers: Object.assign({
						'Content-Type': 'application/json; charset=utf-8'
					}, option.headers),
				}),
				query = URL.toSearch(data);
			if (method == 'GET') {
				url = `${url}${query && `?${query}`}`.replace(
					/(\?.+?)\?(.+)/gm,
					"$1&$2"
				)
			}
			Object.assign(this, {
				timeout,
				responseType,
				withCredentials
			});
			return new Promise((resolve, reject) => {
				if (locks.includes(url)) {
					resolve(new Error());
				} else {
					if (lock) locks.push(url);
					const t = (cb, e) => {
						cb(e);
						const index = locks.indexOf(url);
						index == -1 || locks.splice(index, 1);
					}
					this.ons({
						error: e => t(reject, e),
						timeout: e => t(reject, e),
						load: () => t(resolve, this.response)
					});
					this.open(method, url, async, username, password);
					this.setRequestHeaders(headers);
					this.send.apply(this, method == 'GET' ? [] : [['Object', 'Array'].includes(typeOf(data)) ? JSON.stringify(data) : data]);
				}

			});
		}
	}
});