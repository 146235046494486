/**
 * 加载指令
 */
function loading(el, e) {
	if (e.value) {
		el.classList.add('v-loading');
		Object.assign(el.mask.style, {
			transform: `translate3d(${el.scrollLeft}px,${el.scrollTop}px,0)`
		});
	} else {
		el.classList.remove('v-loading');
	}
}

export default {
	inserted(el, e) {
		let inner = Element.create('div');
		inner.classList.add('inner');
		el.mask = Element.create('div');
		el.mask.classList.add('v-loading-mask');
		el.mask.appendChild(inner);
		el.appendChild(el.mask);
		el.insertBefore(el.mask, el.firstElementChild);
		loading(el, e);
	},
	update(el, e) {
		loading(el, e);
	}
}