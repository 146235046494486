import Scaler from './scaler';

import Button from './button';
import Input from './input';
import Radio from './radio';
import Checkbox from './checkbox';

import Editor from './editor';
import Textarea from './textarea';

import Upload from './upload';
import UploadImage from './upload/image';

import Popover from './popover';
import Select from './select';
import SelectOption from './select/option';
import SelectOptions from './select/options';
import Cascader from './cascader';
import SelectRegion from './select/region';

import DatePanel from './panel/date';
import DateRangePanel from './panel/date-range';
import TimePanel from './panel/time';

import Picker from './picker';
import DatePicker from './picker/date';
import DateRangePicker from './picker/date-range';
import DatetimePicker from './picker/datetime';

import Tree from './tree';
import Pagination from './pagination';

import Message from './dialog/message';

import region from './select/region/data.json';

export default {
	install(Vue, config = {}) {

		Object.entries({

			Scaler,

			Button,
			Input,
			Radio,
			Checkbox,

			Editor,
			Textarea,

			Upload,
			UploadImage,

			Popover,
			Select,
			SelectOption,
			SelectOptions,
			Cascader,
			SelectRegion,

			DatePanel,
			DateRangePanel,
			TimePanel,

			Picker,
			DatePicker,
			DateRangePicker,
			DatetimePicker,

			Tree,
			Pagination,

			Message

		}).forEach(([k, v]) => Vue.component(`V${k}`, v));

		/**
		 * Vue属性扩展
		 */
		Object.defineProperties(Vue.prototype, {
			$disabled: {
				get() {
					return 'disabled' in this.$attrs && (this.$attrs.disabled === '' || this.$attrs.disabled != false);
				}
			},
			$region: {
				value(...args) {
					return args.map(o => (region.filter(r => r.c == o)[0] || {}).n);
				}
			}
		});

		const vm = new Vue({
			el: document.body.appendChild(Element.create("template")),
			render($) { return $('v-message'); }
		}).$children[0];

		/**
		 * 消息弹窗
		 * @param {string} message 消息内容
		 * @param {number} duration 持续时长
		 * @param {string} type 消息类型
		 */
		Vue.prototype.$message = Object.assign(function (message, duration, type) {
			const msg = { message, type, copied: false };
			vm.messages.push(msg);
			duration == Infinity || setTimeout(() => {
				const index = vm.messages.indexOf(msg);
				index == -1 || vm.messages.splice(index, 1);
			}, duration || Math.max(3000, parseInt(message.length / 10) * 1000));
		}, {
			info(message, duration) {
				Vue.prototype.$message(message, duration, 'info');
			},
			success(message, duration) {
				Vue.prototype.$message(message, duration, 'success');
			},
			warn(message, duration) {
				Vue.prototype.$message(message, duration, 'warn');
			},
			error(message, duration) {
				Vue.prototype.$message(message, duration, 'error');
			}
		})

	}
}