/**
 * 滚动到视图中指令
 */
export default {
	inserted(el, e) {
		e.value && el.scrollIntoViewIfNeeded();
	},
	update(el, e) {
		e.value && el.scrollIntoViewIfNeeded();
	}
}