<template>
	<button class="v-radio" :class="{checked}" ref="input">
		<input :id="id" class="inner" @click="trigger" readonly/>
    <slot v-if="'default' in $slots"/>
    <label :for="id" v-else>{{label}}</label>
	</button>
</template>


<script>
export default {
  model: {
    prop: "model"
  },
  props: {
    model: {
      default: null
    },
    value: {
      default: null
    },
    label: {
      default: ""
    }
  },
  data() {
    return {
      id: String.random.uuid()
    };
  },
  computed: {
    checked() {
      return this.model === this.value;
    }
  },
  methods: {
    trigger() {
      if (!this.$disabled) {
        this.$emit("input", this.value);
        this.checked || this.$emit("change", this.value);
      }
    }
  }
};
</script>

<style lang="less">
.v-radio {
  border: none;
  padding: 0px;
  outline: none;
  cursor: pointer;
  font-size: inherit;
  text-align: inherit;
  white-space: nowrap;
  line-height: inherit;
  background-color: transparent;
  &:not(:disabled) {
    &:hover {
      > .inner {
        border-color: #3e946a;
      }
    }
    &.checked {
      > .inner {
        border-color: #3e946a;
        background-color: #3e946a;
        box-shadow: 0 0 0 2px #ffffff inset;
      }
    }
  }
  > .inner {
    width: 16px;
    height: 16px;
    padding: 6px;
    outline: none;
    cursor: inherit;
    border-radius: 50%;
    line-height: inherit;
    border: 2px solid #cccccc;
    background-color: #ffffff;
    transition: 300ms;
  }
  > label {
    cursor: inherit;
  }
}
</style>
