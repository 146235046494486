<template>
	<!-- 目前只有Chorme系的浏览器支持 [contenteditable="plaintext-only"] 属性，以此保留代码注释 -->
	<div class="v-editor" spellcheck="false" :style="style" :context="context" @input="input" ref="input" contenteditable></div>
</template>

<script>
export default {
  props: {
    value: {
      default: ""
    },
    type: {
      default: "text",
      validator(value) {
        return ["text", "html"].includes(value);
      }
    },
    width: {
      default: null
    },
    height: {
      default: null
    }
  },
  computed: {
    Type() {
      return {
        text: "innerText",
        html: "innerHTML"
      }[this.type];
    },
    style() {
      return {
        width: String.unit(this.width, "px"),
        height: String.unit(this.height, "px")
      };
    },
    context: {
      get() {
        if (this.$el && this.$el != document.activeElement) {
          this.$el[this.Type] = this.value;
        }
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    focus() {
      this.$el.focus();
    },
    input(e) {
      this.$emit("input", this.$el[this.Type]);
    },
    exec(command, params) {
      this.focus();
      document.execCommand(command, false, params);
    }
  },
  mounted() {
    this.$el[this.Type] = this.value;
    /*由于H5属性及CSS样式控制粘贴内容还不支持广泛兼容，因此采用JS控制粘贴内容*/
    this.$el.on("paste", e => {
      if (this.type == "text") {
        e.preventDefault();
        this.context = e.clipboardData.getData("text");
      }
    });
    this.$el.on("drop", e => {
      if (this.type == "text") {
        e.preventDefault();
        this.context = e.dataTransfer.getData("text");
      }
    });
  }
};
</script>


<style lang="less">
.v-editor {
  width: 180px;
  cursor: text;
  outline: none;
  overflow: auto;
  font-size: 14px;
  padding: 3px 6px;
  min-height: 32px;
  user-select: none;
  line-height: 24px;
  position: relative;
  border-radius: 4px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  transition: border 300ms;
  &:focus {
    border-color: #3e946a;
  }
  /*此处样式为兼容此属性的Chrome系浏览器做的代码保留 (暂时无意义)*/
  &[contenteditable="plaintext-only"] {
    -webkit-user-modify: read-write-plaintext-only;
  }
}
</style>