<template>
  <button class="v-checkbox" :class="{checked}" ref="input">
    <input :id="id" class="inner" :value="checked?'✓':''" @click="toggle()" readonly/>
    <slot v-if="'default' in $slots"/>
    <label :for="id" v-else>{{label}}</label>
  </button>
</template>

<script>
export default {
  model: {
    prop: "model"
  },
  props: {
    model: {
      default: false
    },
    label: {
      default: null
    },
    value: {
      default: NaN
    },
    onValue: {
      default: true
    },
    offValue: {
      default: false
    }
  },
  data() {
    return {
      id: String.random.uuid()
    };
  },
  computed: {
    checked() {
      return "value" in this.$vnode.componentOptions.propsData
        ? (isArray(this.model) ? this.model : []).includes(this.value)
        : this.model == this.onValue;
    }
  },
  methods: {
    toggle() {
      if (!this.$disabled) {
        if ("value" in this.$vnode.componentOptions.propsData) {
          const model = isArray(this.model) ? this.model : [],
            index = model.indexOf(this.value);
          index == -1 ? model.push(this.value) : model.splice(index, 1);
          this.$emit("input", model);
        } else {
          const value = this.checked ? this.offValue : this.onValue;
          this.$emit("input", value);
          this.checked || this.$emit("change", value);
        }
      }
    }
  }
};
</script>

<style lang="less">
.v-checkbox {
  border: none;
  padding: 0px;
  outline: none;
  cursor: pointer;
  font-size: inherit;
  text-align: inherit;
  white-space: nowrap;
  line-height: inherit;
  background-color: transparent;
  &:disabled {
    > .inner {
      color: #349e69;
    }
  }
  &:not(:disabled) {
    &:hover {
      > .inner {
        border-color: #349e69;
      }
    }
    &.checked {
      > .inner {
        border-color: #349e69;
        background-color: #349e69;
      }
    }
    &:not(.checked).part {
      > .inner {
        background-color: #349e69;
        box-shadow: 0 0 0 3px #ffffff inset;
      }
    }
  }
  > .inner {
    width: 16px;
    height: 16px;
    padding: 0px;
    outline: none;
    cursor: inherit;
    color: #ffffff;
    line-height: 16px;
    user-select: none;
    font-weight: bold;
    text-align: center;
    border-radius: 4px;
    border: 2px solid #dddddd;
    background-color: #ffffff;
    transition: 300ms;
  }
  > label {
    cursor: inherit;
  }
}
</style>
