/**
 * Document原型扩展 
 */
Object.assign(Document.prototype, {
	/**
	 * Icon图标
	 */
	_icon: document.head.appendChild(Object.assign(Element.create("link"), { rel: "icon" }))
});

/**
 * Document属性扩展 
 */
Object.defineProperties(Document.prototype, {
	/**
	 * Icon图标
	 */
	icon: {
		get() {
			return this._icon.href;
		},
		set(value) {
			this._icon.href = value;
		}
	}
});