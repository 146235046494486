/**
 * Storage扩展
 */
Object.assign(Storage, Object.entries({
	local: localStorage,
	session: sessionStorage
}).reduce((p, [k, root]) => {
	return p[k] = Proxy.deep(root, {
		get(target, prop) {
			let $ = `$(${prop})`;
			if (target == root && $ in target) {
				return JSON.parse(target[$]);
			} else {
				return target[prop];
			}
		},
		set(target, prop, value) {
			if (prop in target || target != root) {
				target[prop] = value;
			} else {
				target[`$(${prop})`] = JSON.stringify(value);
			}
		}
	}), p;
}, {}));