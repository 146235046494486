/**
 * CanvasRenderingContext2D原型扩展
 */
Object.assign(HTMLCanvasElement.prototype, {
	/**
	 * 绘制圆角矩形
	 * @param {number} x 左边距
	 * @param {number} y 上边距
	 * @param {number} w 矩形宽度
	 * @param {number} h 矩形高度
	 * @param {number} r 圆角半径
	 */
	drawRoundRect(x = 0, y = 0, w = 0, h = 0, r = 0) {
		this.beginPath();
		this.arc(x + r, y + r, r, Math.PI, (Math.PI * 3) / 2);
		this.lineTo(w - r + x, y);
		this.arc(w - r + x, r + y, r, (Math.PI * 3) / 2, Math.PI * 2);
		this.lineTo(w + x, h + y - r);
		this.arc(w - r + x, h - r + y, r, 0, (Math.PI * 1) / 2);
		this.lineTo(r + x, h + y);
		this.arc(r + x, h - r + y, r, (Math.PI * 1) / 2, Math.PI);
		this.closePath();
	},
})

/**
 * CanvasRenderingContext2D原型扩展
 */
Object.assign(CanvasRenderingContext2D.prototype, {
	/**
	 * 绘制圆角矩形
	 * @param {number} x 左边距
	 * @param {number} y 上边距
	 * @param {number} w 矩形宽度
	 * @param {number} h 矩形高度
	 * @param {number} r 圆角半径
	 */
	drawRoundRect(x = 0, y = 0, w = 0, h = 0, r = 0) {
		this.beginPath();
		this.arc(x + r, y + r, r, Math.PI, (Math.PI * 3) / 2);
		this.lineTo(w - r + x, y);
		this.arc(w - r + x, r + y, r, (Math.PI * 3) / 2, Math.PI * 2);
		this.lineTo(w + x, h + y - r);
		this.arc(w - r + x, h - r + y, r, 0, (Math.PI * 1) / 2);
		this.lineTo(r + x, h + y);
		this.arc(r + x, h - r + y, r, (Math.PI * 1) / 2, Math.PI);
		this.closePath();
	},
	/**
	 * 填充圆角矩形
	 * @param {number} x 左边距
	 * @param {number} y 上边距
	 * @param {number} w 矩形宽度
	 * @param {number} h 矩形高度
	 * @param {number} r 圆角半径
	 */
	fillRoundRect(x = 0, y = 0, w = 0, h = 0, r = 0) {
		this.drawRoundRect(x, y, w, h, r);
		this.fill();
	},
	/**
	 * 旋转角度
	 * @param {number} r 角度
	 */
	rotateAngle(r) {
		let { width, height } = this.canvas,
			x = width / 2, y = height / 2;
		this.translate(x, y);
		this.rotate((r * Math.PI) / 180);
		this.translate(-x, -y);
	},
	/**
	 * 旋转方向
	 * @param {number} o 方向
	 */
	rotateOrient(o) {
		let { width, height } = this.canvas,
			w = [0, 2].includes(o) ? width : height,
			h = [0, 2].includes(o) ? height : width;
		this.translate([0, h, w, 0][o], [0, 0, h, w][o]);
		this.rotate(([0, 90, 180, 270][o] * Math.PI) / 180);
	}
})