<template>
	<v-popover class="v-datetime-picker" trigger="click" @open="()=>$refs.panel.init()" ref="input" keep-active>
		<button slot="trigger" class="selected" :id="id" :placeholder="placeholder">
			<span v-if="value">{{Date.format(model,format)}}</span>
		</button>
    <div class="body">
      <v-date-panel :min-date="minDate" :max-date="maxDate" :init-date="initDate" :format="format" :placeholder="placeholder" v-model="date" ref="panel"/>
      <v-time-panel v-model="time"/>
    </div>
    <div class="foot">
      <v-button type="primary" size="small" @click="confirm()">确认</v-button>
      <v-button size="small" @click="()=>$refs.input.close()">取消</v-button>
    </div>
	</v-popover>
</template>

<script>
export default {
  props: {
    value: {
      default: ""
    },
    id: {
      default: null
    },
    type: {
      default: "text",
      validator(value) {
        return ["text", "timestamp"].includes(value);
      }
    },
    minDate: {
      default: -Infinity
    },
    maxDate: {
      default: Infinity
    },
    initDate: {
      default: null
    },
    format: {
      default: "yyyy-MM-dd HH:mm:ss"
    },
    placeholder: {
      default: "请选择日期时间"
    }
  },
  data() {
    return {
      date: "",
      time: "00:00:00"
    };
  },
  computed: {
    model: {
      get() {
        const [date, time] = Date.format(
          this.value,
          "yyyy-MM-dd HH:mm:ss"
        ).split(" ");
        Object.assign(this, { date, time });
        return this.value;
      }
    }
  },
  methods: {
    confirm() {
      const value = new Date(this.date + " " + this.time);
      if (this.type == "timestamp") {
        value = value.valueOf();
      } else {
        value = value.format("date time");
      }
      this.$emit("input", value);
      if (value != this.value) {
        this.$emit("change", value);
      }
      this.$refs.input.close();
    }
  }
};
</script>

<style lang="less">
.v-datetime-picker {
  min-width: 264px;
  display: inline-flex;
  flex-direction: column;
  &.active {
    > .selected {
      border-color: #3e946a;
    }
  }
  > .selected {
    flex: 1;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    padding: 2px 6px;
    min-height: 32px;
    text-align: left;
    user-select: none;
    line-height: 24px;
    position: relative;
    border-radius: 4px;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    transition: border 300ms;
    &:hover,
    &:focus {
      border-color: #3e946a;
    }
  }
  > .content {
    > .body {
      height: 236px;
      display: flex;
      > .v-date-panel {
        padding: 4px;
      }
      > .v-time-panel {
        border-left: 1px solid #dddddd;
      }
    }
    > .foot {
      padding: 5px;
      text-align: right;
      border-top: 1px solid #dddddd;
    }
  }
}
</style>