<template>
	<div class="v-input" :class="{focus}" ref="input">
		<slot name="before"/>
		<input :type="originType" :min="min" :max="max" :minlength="minLength" :maxlength="maxLength" :width="width" :height="height" :placeholder="placeholder" :autocomplete="autoComplete" v-model="model" @focus="focus=true" @blur="focus=false" @keyup.enter="$emit('search',$event)" ref="inner" :disabled="$attrs.disabled"/>
		<slot name="after"/>
	</div>
</template>

<script>
export default {
  props: {
    value: {
      default: ""
    },
    type: {
      default: null
    },
    min: {
      default: null
    },
    max: {
      default: null
    },
    minLength: {
      default: null
    },
    maxLength: {
      default: null
    },
    width: {
      default: null
    },
    height: {
      default: null
    },
    placeholder: {
      default: null
    },
    autoComplete: {
      default: "off"
    }
  },
  data() {
    return {
      focus: false
    };
  },
  computed: {
    originType() {
      return { integer: "number" }[this.type] || this.type;
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        if (String(value).length) {
          if (this.originType == "number") {
            value = Number(value);
            if (this.type == "integer") {
              value = parseInt(String(value).replace(/\.+/gm, ""));
            }
            if (this.min != null && value < this.min) {
              value = Math.max(value, this.min);
            }
            if (this.max != null && value > this.max) {
              value = Math.min(value, this.max);
            }
          }
        }
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style lang="less">
.v-input {
  width: 264px;
  font-size: 14px;
  // border-radius: 4px;
  display: inline-flex;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  transition: 200ms;
  &:not([theme]) {
    &:hover {
      border-color: #41b883;
    }
    &.focus {
      border-color: #41b883;
    }
    > input {
      &:first-child {
        padding-left: 6px;
      }
      &:last-child:not([type="number"]) {
        padding-right: 6px;
      }
    }
  }
  &:not([size]) {
    line-height: 30px;
  }
  &[size="small"] {
    line-height: 20px;
  }
  > input {
    flex: 1;
    width: 0px;
    border: none;
    padding: 0em;
    outline: none;
    font-size: inherit;
    height: inherit;
    line-height: inherit;
    background-color: transparent;
    &[type="password"] {
      letter-spacing: 4px;
      &::placeholder {
        letter-spacing: initial;
      }
    }
    &[type="search"] {
      &::-webkit-search-cancel-button {
        display: none;
      }
    }
  }
}
</style>