/**
 * File扩展
 */
Object.assign(File, {
	/**
	 * 选择文件
	 * @param {Object} o 配置选项
	 * @return {Promise} 返回Promise对象
	 */
	select(o) {
		o = Object.assign({
			multiple: 1,
			maxSize: Infinity,
			accept: '*',
		}, o);
		return new Promise((resolve, reject) => {
			Object.assign(document.body.appendChild(document.createElement("input")), {
				type: 'file',
				hidden: true,
				accept: o.accept,
				multiple: o.multiple > 1,
				onchange({ target }) {
					target.remove();
					File.filter(target.files, o).then(resolve).catch(reject);
				},
				onerror(e) {
					reject(e);
				}
			}).click();
		});
	},
	/**
	 * 过滤器
	 * @param {FileList} files 文件列表
	 * @param {Object} option 过滤选项
	 * @return {Promise} 返回Promise对象
	 */
	filter(files, option) {
		files = [...files];
		return new Promise((resolve, reject) => {
			if (files.length <= option.multiple) {
				if (files.filter(f => f.size > option.maxSize).length) {
					reject({ type: 'maxSize', maxSize: option.maxSize, files });
				} else if (files.filter(f => !new RegExp(option.accept.replace(/\*/g, '.+')).test(f.type)).length) {
					reject({ type: 'accept', accept: option.accept, files });
				} else {
					resolve(files);
				}
			} else {
				reject({ type: 'multiple' });
			}
		});
	},
	/**
	 * 下载
	 * @param {string} url 下载路径
	 * @param {string} fileName 文件名
	 */
	download(url = '', fileName = '') {
		Object.assign(Element.create("a"), {
			href: url,
			download: fileName
		}).click();
	},
	/**
	 * 保存
	 * @param {Blob} blob Blob对象
	 * @param {string} fileName 文件名
	 */
	save(blob, fileName = '') {
		const url = URL.createObjectURL(blob);
		this.download(url, fileName);
		URL.revokeObjectURL(url);
	}
});