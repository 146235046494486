<template>
	<div class="v-date-panel" ref="input">
		<div class="head">
			<div class="prev">
				<i class="ss-passport icon-arrow-left2" @click="go(-12)"/>
				<i class="ss-passport icon-arrow-left" @click="go(-1)"/>
			</div>
			<div class="center">{{Date.format(cursor,'yyyy年MM月')}}</div>
			<div class="next">
				<i class="ss-passport icon-arrow-right" @click="go(1)"/>
				<i class="ss-passport icon-arrow-right2" @click="go(12)"/>
			</div>
		</div>
		<div class="body">
			<div class="week">
				<div class="day" v-for="(text,i) in ['日','一','二','三','四','五','六']" :key="i">
					<v-scaler class="inner">{{text}}</v-scaler>
				</div>
			</div>
			<div class="week" v-for="(w,i) in matrix" :key="i">
				<div class="day" v-for="(d,j) in w" :key="j">
					<v-scaler class="inner" :class="d.class" @click="select(d)" @mouseenter="$emit('update:hover',d.date)">
            <slot :text="d.text" :date="d.date" v-if="'default' in $scopedSlots"/>
            <template v-else>{{d.text}}</template>
          </v-scaler>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: {
    value: {
      default: ""
    },
    type: {
      default: "text",
      validator(value) {
        return ["text", "timestamp"].includes(value);
      }
    },
    begin: {
      default: null
    },
    end: {
      default: null
    },
    hover: {
      default: null
    },
    initDate: {
      default: null
    },
    minDate: {
      default: -Infinity
    },
    maxDate: {
      default: Infinity
    },
    format: {
      default: "yyyy-MM-dd"
    }
  },
  data() {
    return {
      cursor: Date.today()
    };
  },
  computed: {
    days() {
      let days = [],
        value = Date.today(this.value),
        tomonth = Date.tomonth(this.cursor),
        s = -tomonth.getDay() % 7,
        e = 42 + s,
        minDate = Date.today(this.minDate || Infinity).valueOf() || -Infinity,
        maxDate = Date.today(this.maxDate || Infinity).valueOf() || Infinity,
        begin = Date.today(this.begin || Infinity).valueOf(),
        end = Date.today(this.end || Infinity).valueOf(),
        hover = Date.today(this.hover || Infinity).valueOf(),
        range = [begin || hover, end || hover].sort((a, b) => (a < b ? -1 : 1));
      for (; s < e; s++) {
        let date = new Date(tomonth.valueOf() + s * 86400000);
        days.push({
          date,
          text: date.getDate(),
          isToday: date.valueOf() == Date.today().valueOf(),
          isTomonth: Date.tomonth(date).valueOf() == tomonth.valueOf(),
          isBegin: date.valueOf() == begin,
          included: range[0] < date.valueOf() && date.valueOf() < range[1],
          isEnd: date.valueOf() == end,
          disabled: date < minDate || date > maxDate,
          get class() {
            let {
              isToday: today,
              isTomonth: tomonth,
              isBegin: begin,
              included,
              isEnd: end,
              disabled
            } = this;
            return {
              today,
              tomonth,
              disabled,
              begin,
              included,
              end,
              selected: date.valueOf() == value.valueOf()
            };
          }
        });
      }
      return days;
    },
    matrix() {
      return this.days.reduce((p, u, i) => {
        return i % 7 == 0 ? p.push([u]) : p[p.length - 1].push(u), p;
      }, []);
    }
  },
  methods: {
    go(offset) {
      let month = Date.tomonth(this.cursor);
      this.cursor = new Date(month.setMonth(month.getMonth() + offset));
      this.$emit("cursor-change", this.cursor);
    },
    select({ date, disabled }) {
      if (!disabled) {
        let value = {
          text: Date.format(date, "yyyy-MM-dd"),
          timestamp: date.valueOf()
        }[this.type];
        this.$emit("input", value);
        this.$emit("select", date);
        if (date.valueOf() != new Date(this.value).valueOf()) {
          this.$emit("change", date);
        }
        this.$emit("close");
      }
    },
    init(date) {
      const value = Date.today(this.value || Infinity).valueOf(),
        initDate = Date.today(this.initDate || Infinity).valueOf(),
        minDate = Date.today(this.minDate || Infinity).valueOf(),
        maxDate = Date.today(this.maxDate || Infinity).valueOf();
      this.cursor = Date.today(
        value || date || initDate || minDate || maxDate || undefined
      );
    }
  }
};
</script>

<style lang="less">
.v-date-panel {
  padding: 2px;
  min-width: 200px;
  user-select: none;
  > .head {
    padding: 6px;
    display: flex;
    align-items: center;
    line-height: initial;
    justify-content: space-between;
    > .prev,
    > .next {
      display: flex;
      > .ss-pc {
        margin: 2px;
        cursor: pointer;
        border-radius: 4px;
        &:hover {
          color: #3e946a;
        }
      }
    }
  }
  > .body {
    width: 100%;
    display: table;
    > .week {
      display: table-row;
      &:not(:first-child) {
        > .day {
          > .inner {
            &:hover {
              > .content {
                background-color: #f0f0f0;
              }
            }
            &.tomonth {
              > .content {
                color: #666666;
              }
            }
            &.today {
              > .content {
                color: #3e946a;
                border-color: #3e946a;
                // box-shadow: 0px 0px 0px 1px #3e946a inset;
              }
            }
            &.included {
              &.today {
                > .content {
                  color: #3e946a;
                  border-color: #3e946a;
                }
              }
              &.tomonth {
                > .content {
                  color: #333333;
                }
              }
              > .content {
                color: #999999;
                background-color: rgba(65, 184, 131, 0.2);
              }
            }
            &.selected,
            &.begin,
            &.end {
              &.today {
                > .content {
                  box-shadow: 0px 0px 0px 1px #ffffff inset;
                }
              }
              > .content {
                color: #ffffff;
                background-color: #3e946a;
              }
            }
            &.disabled {
              > .content {
                cursor: no-drop;
                color: #bbbbbb;
                background-color: #f0f0f0;
              }
            }
            > .content {
              cursor: pointer;
              color: #bbbbbb;
              border-radius: 4px;
              transition: 300ms;
            }
          }
        }
      }
      > .day {
        text-align: center;
        border-radius: 4px;
        display: table-cell;
      }
    }
  }
  .v-scaler {
    margin: 2px;
    > .content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>