<template>
	<component class="v-picker" :is="is" :value="value" ref="input"/>
</template>

<script>
export default {
  components: {
    DatePicker: () => import("./date")
  },
  props: {
    value: {
      default: ""
    },
    type: {
      default: ""
    }
  },
  computed: {
    is() {
      return {
        render(createElement) {
          return createElement(`date-picker`, {
            props: this.$vnode.data.attrs
          });
        }
      };
    }
  }
};
</script>