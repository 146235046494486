<template>
	<div class="v-time-panel" ref="input">
    <div class="labels">
      <div class="label">时</div>
      <div class="label">分</div>
      <div class="label">秒</div>
    </div>
    <div class="groups">
      <div class="group" v-for="(group,i) in groups" :key="i" ref="group" v-scrollbar>
        <div class="item" :class="{active:o==selected[i]}" v-for="o in group" :key="o" @click="select(o,i)">{{o}}</div>
      </div>
    </div>
	</div>
</template>

<script>
export default {
  props: {
    value: {
      default: ""
    },
    format: {
      default: "HH:mm:ss"
    }
  },
  data() {
    return {
      groups: [
        Array.from({ length: 24 }).map((o, i) => Number.fillZero(i, 2)),
        Array.from({ length: 60 }).map((o, i) => Number.fillZero(i, 2)),
        Array.from({ length: 60 }).map((o, i) => Number.fillZero(i, 2))
      ]
    };
  },
  computed: {
    selected: {
      get() {
        return String(this.value || "").split(":");
      },
      set(value) {
        this.$emit("input", value.map(o => o || "00").join(":"));
      }
    }
  },
  methods: {
    select(o, i) {
      const [h, m, s] = this.selected,
        selected = [h, m, s];
      selected[i] = o;
      this.selected = selected;
    }
  }
};
</script>

<style lang="less">
.v-time-panel {
  user-select: none;
  display: inline-flex;
  flex-direction: column;
  > .labels {
    display: flex;
    border-bottom: 1px solid #dddddd;
    > .label {
      flex: 1;
      font-size: 12px;
      color: #999999;
      padding: 5px 10px;
      text-align: center;
    }
  }
  > .groups {
    flex: 1;
    height: 0px;
    display: flex;
    > .group {
      width: 50px;
      position: relative;
      text-align: center;
      &:not(:first-child):not(:last-child) {
        border-style: solid;
        border-width: 0px 1px;
        border-color: #dddddd;
      }
      > .item {
        cursor: pointer;
        padding: 0px 10px;
        line-height: 28px;
        transition: 200ms;
        &:hover {
          background-color: #f0f0f0;
        }
        &.active {
          top: 0px;
          bottom: 0px;
          color: #ffffff;
          position: sticky;
          background-color: #349e69;
        }
      }
    }
  }
}
</style>