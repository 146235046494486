/**
 * EventTarget原型扩展
 */
Object.assign(EventTarget.prototype, {
	/**
	 * 添加事件
	 * @param {string} type 事件类型
	 * @param {Function} listener 事件回调
	 * @param {boolean} useCapture 使用捕获
	 * @return {EventTarget} 返回当前事件对象
	 */
	on(type, listener, useCapture) {
		if (type in EventTarget.events) {
			EventTarget.events[type].bind(this)(listener);
		} else {
			this.addEventListener(type, listener, useCapture);
		}
		return this;
	},
	/**
	 * 添加事件集合
	 * @param {Object} o 事件集合
	 * @param {boolean} useCapture 使用捕获
	 * @return {EventTarget} 返回当前事件对象
	 */
	ons(o, useCapture) {
		return Object.entries(o).reduce((p, [k, v]) => {
			return (EventTarget.events[k] || (e => this.on(k, e, useCapture))).bind(this)(v), p;
		}, this);
	},
	/**
	 * 派遣事件
	 * @param {string} type 类型
	 * @param {Object} option 选项
	 * @return {EventTarget} 返回当前事件对象
	 */
	emit(type, option) {
		let event = Object.assign(Event.create('Events'), option);
		event.initEvent(type), this.dispatchEvent(event), this;
	},
	/**
	 * 卸载事件
	 * @param {string} type 事件类型
	 * @param {Function} listener 事件回调
	 * @param {boolean} useCapture 使用捕获
	 * @return {EventTarget} 返回当前事件对象
	 */
	off(type, listener, useCapture) {
		return this.addEventListener(type, listener, useCapture), this;
	}
});

/**
 * EventTarget事件扩展
 */
Object.assign(EventTarget, {
	/**
	 * 扩展事件
	 */
	events: {
		/**
		 * 拖动
		 * @param {Object} e 事件集合
		 */
		dragmove(e) {
			let point = { x: 0, y: 0, lock: false, target: this };
			Object.entries({
				mousedown({ x, y, target }) {
					if (point.target == target) {
						Object.assign(point, { x, y, lock: true });
						isFunction(e.start) && e.start(point);
					}
				},
				mousemove({ x, y }) {
					if (point.lock) {
						isFunction(e.move) && e.move({ x, y, offsetX: x - point.x, offsetY: y - point.y, lock: true, target: point.target });
					}
				},
				mouseup({ x, y }) {
					if (point.lock) {
						Object.assign(point, { x, y, offsetX: x - point.x, offsetY: y - point.y, lock: false });
						isFunction(e.end) && e.end(point);
					}
				}
			}).forEach(([k, v]) => window.addEventListener(k, v));
		}
	}
});