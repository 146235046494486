/**
 * URL扩展
 */
Object.assign(URL, {
	/**
	 * 解析
	 * @param {string} url 路径 
	 * @return {Object} 返回解析后的对象
	 */
	parse(url) {
		let [
			href = '',
			origin = '',
			protocol = '',
			host = '',
			hostname = '',
			port = '',
			pathname = '',
			search = '',
			hash = ''
		] = (/(([^\/]*)\/\/(([^:\/]*):?([^\/]*)))?([^\?#]*)(\?[^#]*)?(.*)/gm).exec(url), [
			pathname2,
			path,
			file,
			filename,
			extname
		] = (/(.*\/)*(([^\/\.]*)\.?(.*)?)/gm).exec(pathname);
		return {
			href,
			origin,
			protocol,
			host,
			hostname,
			port,
			pathname,
			path,
			file,
			filename,
			extname,
			search,
			hash
		}
	},
	/**
	 * 路径合成
	 * @param {string} first 首个参数
	 * @param {string} surplus 剩余参数
	 * @return {string} 返回合成后的路径
	 */
	path(first, ...surplus) {
		return surplus.reduce((p, o) => String(p).replace(/^(.*[^\/])\/+$/, '$1') + String(o).replace(/^\/*(.+)$/, '/$1'), first);
	},
	/**
	 * 转换对象到Query字符串
	 * @param {Object} target 目标对象
	 * @return {string} 返回Query字符串
	 */
	toSearch(target = {}) {
		return Object.entries(Object.unfold(target)).reduce((p, [k, v]) => {
			return p.push(`${k}=${encodeURIComponent(v)}`), p;
		}, []).join('&');
	},
	/**
	 * 解析Query字符串到对象 (默认转换数值类型数据)
	 * @param {string} search Search字符串
	 * @param {boolean} mode 数值转换模式(默认为False)
	 * @return {Object} 返回解析后的对象
	 */
	toQuery(search = '', mode = false) {
		return Object.entries(String(search).replace(/^\?/gm, '').split('&')
			.filter(s => s)
			.map(s => s.split('='))
			.reduce((p, [k, v]) => {
				v = decodeURIComponent(v);
				if (k in p) {
					let w = p[k];
					if (isArray(w)) {
						w.push(v);
					} else {
						p[k] = [w, v];
					}
				} else {
					p[k] = v;
				}
				return p;
			}, {})).reduce((p, [key, value]) => {
				return key.replace(/(\[)/gm, '.$1').split('.')
					.reduce((q, k, i, a) => {
						if (k in q) {
							return q[k];
						} else {
							let key = ((/^\[(.*)\]$/gm).exec(k) || [])[1] || k;
							if (i == a.length - 1) {
								if (isArray(value)) {
									return q[key] = value.map(v => mode && parseFloat(v) || v)
								} else {
									return q[key] = mode && parseFloat(value) || value
								}
							} else {
								let o = ((/^\[(.*)\]$/gm).exec(a[i + 1]) || [])[1] ? [] : {};
								if (typeOf(q[key]) == typeOf(o)) {
									return q[key];
								} else {
									return q[key] = o;
								}
							}
						}
					}, p), p;
			}, {});
	},
	/**
	 * 转换base64字符串到Uint8Array对象
	 * @param {string} base64 base64字符串
	 * @return {Uint8Array} 返回转换后的Uint8Array对象
	 */
	toUint8Array(base64) {
		let b = atob(base64), l = b.length, u = new Uint8Array(l);
		for (let i = 0; i < l; i++) u[i] = b.charCodeAt(i);
		return u;
	},
	/**
	 * 转换base64字符串到Blob对象
	 * @param {string} base64 base64字符串
	 * @param {string} type Mime类型
	 * @return {Blob} 返回转换后的Blob对象
	 */
	toBlob(base64, type) {
		let [x, t, s] = /^data:(.*?);base64,(.*?)$/.exec(base64);
		return new Blob([this.toUint8Array(s)], { type: type || t });
	},
	/**
	 * 转换base64字符串到File对象
	 * @param {string} base64 base64字符串
	 * @param {string} option Mime类型
	 * @return {Blob} 返回转换后的File对象
	 */
	toFile(base64, option) {
		option = option || { type: t, name: '' };
		let [x, t, s] = /^data:(.*?);base64,(.*?)$/.exec(base64);
		return new File([this.toUint8Array(s)], option.name || '', { type: option.type || t });
	}
});