<template>
	<button class="v-upload" :id="id" @click="open">
		<slot/>
	</button>
</template>

<script>
export default {
  props: {
    id: {
      default: ""
    },
    multiple: {
      default: 1
    },
    maxSize: {
      default: Infinity
    },
    accept: {
      default: "*"
    }
  },
  methods: {
    open() {
      let { multiple, maxSize, accept } = this;
      File.select({ multiple, maxSize, accept })
        .then(files => this.$emit("select", files))
        .catch(e => this.$emit("error", e));
    }
  }
};
</script>

<style lang="less">
.v-upload {
  padding: 0px;
  outline: none;
  cursor: pointer;
  &[disabled] {
    pointer-events: none;
  }
}
</style>
