/**
 * Window原型扩展
 */
Object.assign(Window.prototype, {
	/**
	 * Ajax (基于XMLHttpRequest Level 2)
	 * @param {Object} config 初始化配置 
	 * @return {Function} 返回实例回调
	 */
	Ajax(config = {}) {
		let ajax = Object.assign(function (o) {
			o = isObject(o) ? o : { url: o };
			o.headers = Object.assign({}, ajax.config.headers, config.headers, o.headers);
			o = Object.assign({}, ajax.config, config, o);
			return new Promise((resolve, reject) => {
				o.request(o, ({
					data,
					type,
					method,
					timeout,
					async,
					username,
					password,
					headers,
					credentials,
					cache,
					lock,
					uploading,
					downloading,
					response
				}) => {
					let xhr = new XMLHttpRequest(),
						[path, search = ''] = o.url.split('?'),
						query = Object.assign(URL.toQuery(search), method == 'GET' ? data : {}),
						url = path + URL.toSearch(query).replace(/^(.+)$/, '?$1');
					if (url in o.store && Date.now() - o.store[url][0] < cache) {
						resolve(o.store[url][1]);
					} else if (!ajax.locks.includes(url)) {
						lock && ajax.locks.push(url);
						xhr.timeout = timeout;
						xhr.responseType = type;
						xhr.withCredentials = credentials;
						xhr.onprogress = downloading;
						xhr.upload.onprogress = uploading;
						xhr.onerror = xhr.ontimeout = e => reject(e);
						xhr.on('load', () => {
							ajax.unlock(url);
							if (xhr.status == 200) {
								response(xhr.response, response => {
									if (cache > 0) {
										o.store[url] = [Date.now(), response];
									}
									resolve(response);
								}, reject);
							} else {
								reject(xhr);
							}
						});
						xhr.open(method, url, async, username, password);
						Object.entries(headers).forEach(([k, v]) => v && xhr.setRequestHeader(k, v));
						xhr.send.apply(xhr, method == 'GET' ? [] : [['Object', 'Array'].includes(typeOf(data)) ? JSON.stringify(data) : data]);
					}
				});
			});
		}, ['GET', 'POST', 'PUT', 'DELETE'].reduce((p, method) => {
			return p[method.toLowerCase()] = function (o, data) {
				o = isObject(o) ? o : { url: o };
				return this(Object.assign({ method }, o, { data: Object.assign({}, o.data, data) }));
			}, p;
		}, {
			/**
			 * 配置
			 */
			config: {
				type: 'json',
				method: 'GET',
				timeout: 0,
				async: true,
				username: null,
				password: null,
				credentials: false,
				cache: 0,
				lock: false,
				store: {},
				headers: {
					'Content-Type': 'application/json; charset=utf-8'
				},
				request: (req, next) => next(req),
				response: (res, next) => next(res)
			},
			/**
			 * 锁
			 */
			locks: [],
			/**
			 * 解锁
			 */
			unlock(url) {
				let index = this.locks.indexOf(url);
				index == -1 || this.locks.splice(index, 1);
			},
			/**
			 * 上传
			 * @param {Object} o 配置选项
			 * @return {Promise} 返回Promise对象
			 */
			upload(o) {
				return this(Object.assign({
					timeout: 0,
					method: 'POST',
					headers: { 'Content-Type': null },
					uploading: o.progress
				}, o));
			},
			/**
			 * 下载
			 * @param {Object} o 配置选项
			 * @return {Promise} 返回Promise对象
			 */
			download(o) {
				return this(Object.assign({
					timeout: 0,
					type: 'blob',
					method: 'GET',
					downloading: o.progress
				}, o));
			}
		}));
		return ajax;
	}
})