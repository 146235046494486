/**
 * Window原型扩展
 */
Object.defineProperties(Window.prototype, {
	typeOf: {
		value(o) {
			return isEmpty(o) ? String(o) : (/^function (.*)\(/).exec(o.constructor.toString())[1];
		}
	},
	isUndefined: {
		value(o) {
			return o === undefined;
		}
	},
	isNull: {
		value(o) {
			return o === null;
		}
	},
	isEmpty: {
		value(o) {
			return o == null;
		}
	},
	isBlank: {
		value(o) {
			return isEmpty(o) || o === '';
		}
	},
	isBoolean: {
		value(o) {
			return typeof o === 'boolean';
		}
	},
	isNumber: {
		value(o) {
			return !isNaN(o) && typeof o === 'number';
		}
	},
	isInteger: {
		value(o) {
			return Number.isInteger(o);
		}
	},
	isString: {
		value(o) {
			return typeof o === 'string';
		}
	},
	isDate: {
		value(o) {
			return o instanceof Date;
		}
	},
	isArray: {
		value(o) {
			return o instanceof Array;
		}
	},
	isFunction: {
		value(o) {
			return o instanceof Function;
		}
	},
	isRegExp: {
		value(o) {
			return o instanceof RegExp;
		}
	},
	isPromise: {
		value(o) {
			return o instanceof Promise;
		}
	},
	isObject: {
		value(o) {
			return o instanceof Object;
		}
	},
	/**
	 * 浏览器属性
	 */
	browser: {
		value: {
			userAgent: navigator.userAgent.replace('rv:', 'MSIE/'),
			platform: (/(Mobile)?/gmi).exec(navigator.userAgent)[1] || 'PC',
			device: (/Android|iPhone|iPad|Macintosh|Windows/gmi).exec(navigator.userAgent)[0],
			get type() {
				return ['MSIE', 'Edge', 'Firefox', 'Chrome', 'Safari'].reduce((p, t) => p || (this.userAgent.includes(t) ? t : p), '');
			},
			get version() {
				switch (this.type) {
					default:
						return new RegExp(`${this.type}/(\\d+)`).exec(this.userAgent)[1]
				}
			}
		}
	}
});