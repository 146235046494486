import Loading from './loading';
import Scrollbar from './scrollbar';

import Intoview from './intoview';

export default {
	install(Vue, config) {

		Object.entries({

			Loading,
			Scrollbar,

			Intoview

		}).forEach(([k, v]) => Vue.directive(k, v));

	}
}