/**
 * FormData扩展
 */
Object.assign(FormData, {
	/**
	 * 转换对象到FormData对象
	 * @param {Object} target 目标对象
	 * @return {FormData} 返回转换后的FormData对象
	 */
	from(target = {}) {
		return Object.entries(target).reduce((p, [k, v]) => {
			return p.append(k, v), p;
		}, new FormData());
	},
});