/**
 * Element扩展
 */
Object.assign(Element, {
	/**
	 * 创建元素
	 * @param {string} name 标签名称
	 * @param {Object} options 选项参数
	 * @return {Element} 返回创建的元素
	 */
	create(name, options) {
		return Object.assign(document.createElement(name), options);
	},
	/**
	 * 删除元素
	 */
	remove() {
		return this.parentNode.removeChild(this);
	}
});

/**
 * Element属性扩展
 */
Object.defineProperties(Element.prototype, {
	css: {
		get() {
			return getComputedStyle(this);
		}
	},
	rect: {
		get() {
			return this.getBoundingClientRect();
		}
	}
});