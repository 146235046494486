<template>
	<div class="v-select-option" :class="{selected}" @click="select">
		<slot v-if="'default' in $slots"/>
		<div class="label" v-else>{{label}}</div>
	</div>
</template>

<script>
export default {
  props: {
    value: {
      default: ""
    },
    label: {
      default: ""
    }
  },
  computed: {
    parent() {
      return this.$parent.$parent;
    },
    selected() {
      let { value } = this.parent,
        selected = isArray(value) ? value : isBlank(value) ? [] : [value];
      return this.parent.maxCount
        ? selected.includes(this.value)
        : this.parent.selected === this.value;
    }
  },
  methods: {
    select() {
      if (this.parent) {
        if (this.parent.maxCount > 1) {
          let { value } = this.parent,
            selected = isArray(value) ? value : isBlank(value) ? [] : [value];
          let index = selected.indexOf(this.value);
          if (index == -1) {
            if (this.parent.selected.length < this.parent.maxCount) {
              selected.push(this.value);
            }
          } else {
            selected.splice(index, 1);
          }
          this.parent.selected = selected;
        } else {
          this.parent.selected = this.value;
        }
      }
    }
  },
  mounted() {
    this.parent.options.push(this);
  },
  destroyed() {
    let index = this.parent.options.indexOf(this);
    index == -1 || this.parent.options.splice(index, 1);
  }
};
</script>

<style lang="less">
.v-select-option {
  cursor: pointer;
  transition: 300ms;
  user-select: none;
  white-space: nowrap;
  line-height: initial;
  &:hover {
    background-color: #dcf3e8;
  }
  &.selected {
    top: 0px;
    bottom: 0px;
    position: sticky;
    background-color: #e8e8e8;
  }
  > .label {
    margin: 2px 0px;
    padding: 5px 10px;
  }
}
</style>