/**
 * Window原型扩展
 */
Object.assign(Window.prototype, {
	/**
	 * 错误消息
	 */
	Errors(target) {
		Object.defineProperty(this, 'length', { value: 0, writable: true });
		if (isObject(target)) {
			return Object.entries(target).reduce((p, [k, v]) => p.append(k, v), new Errors())
		}
	}
});

/**
 * Errors属性扩展
 */
Object.defineProperties(Errors.prototype, {
	splice: { value: Array.prototype.splice },
	[Symbol.iterator]: { value: Array.prototype[Symbol.iterator] },
	append: { value(k, v) { if (v) this[k] = this[this.length++] = v; return this; } },
	clear: { value() { this.length = 0; for (let i in this) { delete this[i] } } }
});

