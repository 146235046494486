<template>
	<transition-group tag="div" class="v-message-group" name="message">
		<div class="message" :class="o.type" v-for="(o,i) in messages" :key="o.__ob__.dep.id">
      <div class="content">
        <i class="ss-pc" :class="`icon-${o.type}`"/>
        <div class="context" title="双击复制" @dblclick="copy($event,o)" v-scrollbar>{{o.message}}</div>
      </div>
      <i class="ss-passport icon-clear" @click="messages.splice(i,1)"/>
      <div class="copied" :class="{show:o.copied}">已复制</div>
    </div>
	</transition-group>
</template>

<script>
export default {
  data() {
    return {
      messages: []
    };
  },
  methods: {
    copy(e, o) {
      try {
        const selection = getSelection(),
          range = document.createRange();
        range.selectNode(e.target);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
        o.copied = true;
      } catch (e) {}
    }
  }
};
</script>

<style lang="less">
.v-message-group {
  top: 0px;
  left: 50%;
  z-index: 1;
  overflow: auto;
  position: fixed;
  margin: 0px auto;
  max-height: 100%;
  padding: 0px 55px;
  transform: translate3d(-50%, 0, 0);
  > .message {
    opacity: 1;
    margin: 10px;
    position: relative;
    visibility: visible;
    transition: 300ms;
    &::selection {
      color: #ffffff;
    }
    &:hover {
      > .icon-clear {
        opacity: 1;
        visibility: visible;
      }
    }
    > .copied {
      top: 5px;
      left: 100%;
      opacity: 0;
      font-size: 12px;
      content: "已复制";
      color: #ffffff;
      padding: 4px 8px;
      position: absolute;
      visibility: hidden;
      border-radius: 4px;
      white-space: nowrap;
      background-color: rgba(0, 0, 0, 0.5);
      transform: translate3d(-10px, 0, 0) scale3d(0.5, 0.5, 1);
      transition: 300ms;
      &::before {
        top: 0px;
        left: 0px;
        z-index: 1;
        content: "";
        position: absolute;
        border-style: solid;
        display: inline-block;
        border-width: 5px 5px 5px 0px;
        border-color: transparent rgba(0, 0, 0, 0.5) transparent transparent;
        transform: translate3d(-5px, 7px, 0);
      }
      &.show {
        opacity: 1;
        visibility: visible;
        transform: translate3d(10px, 0, 0) scale3d(1, 1, 1);
      }
    }
    &.info > .content {
      color: #1890ff;
      border-color: #1890ff;
      > .context::selection {
        background-color: #1890ff;
      }
    }
    &.success > .content {
      color: #52c41a;
      border-color: #52c41a;
      > .context::selection {
        background-color: #52c41a;
      }
    }
    &.warn > .content {
      color: #fa8c16;
      border-color: #fa8c16;
      > .context::selection {
        background-color: #fa8c16;
      }
    }
    &.error > .content {
      color: #f5222d;
      border-color: #f5222d;
      > .context::selection {
        background-color: #f5222d;
      }
    }
    &.message-enter,
    &.message-leave-to {
      opacity: 0;
      visibility: hidden;
      transform: translate3d(0, -50%, 0) scale3d(0.6, 0.6, 1);
    }
    > .content {
      display: flex;
      overflow: auto;
      min-width: 200px;
      max-width: 600px;
      max-height: 300px;
      padding: 5px 15px;
      line-height: 20px;
      // border-radius: 16px;
      white-space: pre-wrap;
      background-color: #ffffff;
      border: 1px solid transparent;
      box-shadow: 0 0 8px rgba(127, 127, 127, 0.5);
      > i {
        font-size: 16px;
        margin-right: 6px;
      }
    }
    > .icon-clear {
      top: 7px;
      right: 7px;
      opacity: 0;
      width: 18px;
      height: 18px;
      line-height: 1;
      cursor: pointer;
      font-size: 18px;
      color: #7a6d6d;
      position: absolute;
      visibility: hidden;
      border-radius: 50%;
      background-color: #ffffff;
      transition: 200ms;
    }
  }
}
</style>