/**
 * String扩展
 */
Object.assign(String, {
	/**
	 * 单位
	 * @param {number|string} value 值
	 * @param {string} unit 单位
	 * @return {string} 返回带有单位的字符串
	 */
	unit(value = '', unit = '') {
		return String(value).replace(new RegExp(`^(\\-?\\d|\\-?[1-9]\\d*|\\-?\\d\\.\\d+|\\-?[1-9]\\d*\\.\\d+)(${unit})?$`, 'gm'), `$1${unit}`);
	},
	/**
	 * 字符串模板
	 * @param {string} value 值
	 * @param {Object} data 数据对象
	 * @param {Object} option 选项
	 */
	template(value = '', data = {}, option = { before: '{', after: '}' }) {
		return Object.entries(Object.unfold(data)).reduce((p, [k, v]) => p.replace(new RegExp(`${option.before}${k}${option.after}`, 'gm'), v), value);
	},
	/**
	 * 随机字符串
	 * @param {string} exp 表达式
	 * @return {string} 返回生成的随机字符串
	 */
	random: Object.assign(function (exp = '') {
		return exp.replace(/[*]/g, c => {
			let r = Math.random() * 16 | 0;
			return (c == '*' ? r : (r & 0x3 | 0x8)).toString(16);
		});
	}, {
			/**
			 * 生成uuid
			 * @param {number} length 内容长度
	 		 * @return {string} 返回指定长度的uuid
			 */
			uuid(length = 32) {
				return this(Array.from({ length }).map(() => '*').join(''));
			}
		}),
});