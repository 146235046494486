<template>
	<v-popover class="v-date-range-picker" trigger="click" @open="()=>$refs.panel.init()" ref="input" keep-active>
		<button slot="trigger" class="selected" :placeholder="placeholder">
      <template v-if="value&&value.filter(o=>o).length">
        <span>{{value[0]}}</span>
        <span>~</span>
        <span>{{value[1]}}</span>
      </template>
		</button>
		<v-date-range-panel :type="type" :min-date="minDate" :max-date="maxDate" :format="format" :placeholder="placeholder" v-model="model" @close="()=>$refs.input.close()" ref="panel"/>
	</v-popover>
</template>

<script>
export default {
  props: {
    value: {
      default: ""
    },
    type: {
      default: "text",
      validator(value) {
        return ["text", "timestamp"].includes(value);
      }
    },
    minDate: {
      default: -Infinity
    },
    maxDate: {
      default: Infinity
    },
    format: {
      default: "yyyy-MM-dd"
    },
    placeholder: {
      default: "请选择日期范围"
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        let range = value.map(o => o);
        this.$emit("input", range);
        this.$emit("change", range);
      }
    }
  },
  methods: {
    open() {
      this.cursor = Date.today(this.value);
    },
    go(offset) {
      let month = Date.tomonth(this.cursor);
      this.cursor = new Date(month.setMonth(month.getMonth() + offset));
    }
  }
};
</script>

<style lang="less">
.v-date-range-picker {
  width: 264px;
  display: inline-flex;
  flex-direction: column;
  &.active {
    > .selected {
      border-color: #3e946a;
    }
  }
  > .selected {
    flex: 1;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    min-height: 32px;
    text-align: left;
    user-select: none;
    line-height: 24px;
    position: relative;
    border-radius: 4px;
    padding: 2px 6px 2px 6px;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    transition: border 300ms;
    &:hover,
    &:focus {
      border-color: #3e946a;
    }
  }
}
</style>