<template>
	<div class="v-textarea" :style="style" v-scrollbar.inner="0" ref="input">
		<textarea :id="id" :placeholder="placeholder" :maxlength="maxLength" v-model="model"/>
	</div>
</template>

<script>
export default {
  props: {
    value: {
      default: ""
    },
    id: {
      default: null
    },
    width: {
      default: null
    },
    height: {
      default: null
    },
    maxLength: {
      default: null
    },
    placeholder: {
      default: null
    }
  },
  computed: {
    style() {
      return {
        width: String.unit(this.width, "px"),
        height: String.unit(this.height, "px")
      };
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style lang="less">
.v-textarea {
  width: 304px;
  height: 32px;
  display: inline-flex;
  box-sizing: border-box;
  background-color: #ffffff;
  > textarea {
    flex: 1;
    resize: none;
    outline: none;
    font-size: 14px;
    padding: 6px 6px;
    line-height: 18px;
    border-radius: 4px;
    font-family: inherit;
    border: 1px solid #cccccc;
    background-color: transparent;
    transition: border-color 300ms;
    &:focus {
      position: relative;
      border-color: #3e946a;
    }
  }
}
</style>
